<template>
  <div>
    <div class="pro_ban">
      <img src="@/assets/news.png"
           alt="杲弢新闻" />
    </div>
    <div class="proinfo">
      <div class="proinfo_">
        <h2>{{Info.title}}</h2>
        <div class="proinfo_tas">
          <span>时间：{{ Info.createTime }}</span>
          <span>作者：{{ Info.img }}</span>
          <span>浏览量：{{ Info.state }}</span>
        </div>
      </div>
    </div>

    <div class="proinfo_productDescription">
      <div>
        {{ Info.categoryContent }}
      </div>
    </div>
  </div>
</template>

<script>
import { dynamicById } from '@/api/gw.js'
export default {
  data () {
    return {
      Info: {},
    }
  },
  methods: {
    getAllInfo () {
      dynamicById({ id: this.$route.params.id }).then(res => {
        this.Info = res.data.data
      })
    },
    getId () {

    },
  },
  mounted () {
    this.getId();
    this.getAllInfo();

  }
};
</script>

<style>
.proinfo_,
.proinfo_productDescription {
  width: 80%;
  margin: 0 auto;
}
.proinfo_ h2 {
  text-align: center;
  margin: 1% 0;
  font-size: 25px;
  font-weight: 600;
}
.proinfo_tas {
  text-align: center;
}
</style>