<template>
  <div>
    <div class="troPc">
      <div class="pro_ban">
        <img src="@/assets/cppx.png"
             alt="产品培训"/>
      </div>
      <!-- 面包屑 -->
      <div class="mbx">
        <el-breadcrumb separator-class="el-icon-arrow-right"
                       replace>
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <p>产品培训</p>
            <!-- <p v-for="(item, index) in nav"
               :key="index">
              <span @click="getNav(index++)"
                    v-if="index == prdCategoryId">{{item.name}}</span>
            </p> -->
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="cppx">
        <div class="pro-1">
          <p class="pro_zh">产品培训</p>
          <p class="pro_en">PRODUCT CENTER</p>
          <p class="pro_xian"></p>
        </div>
        <div class="cppx-2">
          <div class="cppx-2-1"
               v-for="(item,index) in newsId"
               :key="index">
            <router-link :to="{path:'/TroubleInfo',query:{id:item.detailsId}}">
              <img :src="item.coverImage"
                   alt="">
              <div class="cppx_2_2_hover">
                <div class="cppx_2_2">
                  <p class="cppx_p1">{{ item.title }}</p>
                  <p class="cppx_p2">{{ item.categoryContent }}</p>
                  <p class="cppx_p4">> 产品类型:{{ item.categoryName }}</p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <el-row>
        <el-col :span="16" v-for="(news, index) in news" :key="index" :offset="index > 0 ? 2 : 0">
          <el-card :body-style="{ padding: '0px' ,display: 'flex'}">
            <img src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png" class="image"/>
            <div style="padding: 14px;width: 100%;">
              <span>{{news.title}}</span>
              <div class="bottom clearfix">
                <time class="time">{{ news.createTime }}</time>
                <p>{{news.categoryContent}}</p>
                <el-button type="text" class="button">操作按钮</el-button>
              </div>
            </div>
          </el-card>
          <div class="product">
            <p>推荐产品</p>
          </div>
        </el-col>

      </el-row> -->
    </div>
    <!--  手机端  -->
    <div class="troTel">
      <!--轮播     el-carousel__container  -->
      <div class="banTel">
        <el-carousel :interval="5000" arrow="never" class="el-carousel-container">
          <el-carousel-item v-for="item in imgListTel"
                            :key="item.id">
            <img class="banTel-img" :src="item.idView"
                 alt="杲弢">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--公告      -->
      <div class="lb_te">
        <img class="sy_gg_img"
             src="@/assets/sy_gg.png">
        <p class="sy_gg">公告</p>
        <p class="sy_gg_sx"></p>
        <el-carousel height="30px"
                     :interval="33333000"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none">
          <el-carousel-item v-for="item in items"
                            :key="item">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--培训      -->
      <div class="troTel-con">
        <div class="troTel-con-title">
          <p class="troTel-title-p1">产品培训</p>
          <p class="troTel-title-p2">HONOR</p>
        </div>
        <div class="troTel-for">
          <div v-for="(item,index) in newsId" class="troTel-con-for"
               :key="index">
            <router-link :to="{path:'/TroubleInfo'}">
              <div class=""><img :src="item.coverImage" alt=""></div>
              <p class="troTel-con-for-p1">{{ item.title }}</p>
              <p class="troTel-con-for-p2">{{ item.categoryContent }}</p>
              <p class="troTel-con-for-p3"> >产品类型:{{ item.categoryName }}</p>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import {dynamicList, dynamicListId, dynamicListById} from '@/api/gw.js'

export default {
  data() {
    return {
      news: [],
      newsId: [],
      /*轮播*/
      imgListTel: [
        {id: 0, idView: require('@/assets/telban.png')},
        {id: 1, idView: require('@/assets/telban.png')},
        {id: 2, idView: require('@/assets/telban.png')}
      ],
      /*下单*/
      items: [
        '**客户下单2桶BBA',
        '**客户下单6桶BBA 4桶GTPC',
        '**客户下单2桶GTPC 6桶PTRA',
        '**客户下单6桶MP1H 1桶PTRA',
        '**客户下单2桶ZSJ 5桶PTRA',
        '**客户下单1桶2HMP 3桶PTRA',
      ],
    }
  },
  methods: {
    DynamicList() {
      dynamicList().then(res => {
        this.news = res.data.page.list
      })
    },
    DynamicListId() {
      dynamicListId({categoryId: 1}).then(res => {
        this.newsId = res.data.page.list
        console.log(res);
      })
    },
    DynamicListById() {
      dynamicListById(this.detailsId).then(res => {
        console.log(res);
      })
    },

  },
  mounted() {
    this.DynamicList();
    this.DynamicListId()
  }
};
</script>

<style scoped>
/*Tel*/
@media screen and (max-width: 600px) {
  .troPc {
    display: none;
  }

  .troTel {
    display: block;
  }

  /*轮播*/
  /deep/ .el-carousel__container{
    height: auto;
  }

  .banTel-img {
    width: 100% !important;
  }

  .el-carousel-container {
    height: 190px;
  }

  /deep/ .el-car-container {
    height: auto;
  }

  /*轮播 end*/
  /*公告*/
  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel {
    margin: 0 auto;
    width: inherit;
  }

  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .el-carousel__item {
    width: 100%;
    margin: 0 auto;
  }

  .el-carousel {
    margin: 0 auto;
  }


  .el-carousel--horizontal {
    overflow-x: hidden;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }
  /*公告 end*/
  /*留言*/
  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }

  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel__item {
    height: auto;
  }

  /*留言 end*/
  /*培训*/
  .troTel-con-title{
    text-align: center;
  }
  .troTel-title-p1 {
    font-size: 1.2rem;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
    opacity: 1;
    line-height: 1.5;
  }

  .troTel-title-p2 {
    font-size: 1em;
    font-family: Source Han Sans SC;
    font-weight: 300;
    line-height: 13px;
    color: #666666;
    opacity: 1;
    line-height: 1.5;
  }
  .troTel-for {
    display: flex;
    flex-wrap: wrap;
  }

  .troTel-con-for {
    width: 45%;
    margin: 2%;
    border: 1px solid #0077FF;
    text-align: center;
  }


  .troTel-con-for img {
    width: 100%;
  }
  .troTel-con-for-p1 {
  padding: 2% 0 0 0;
  font-weight: 600;
}
  .troTel-con-for-p2 {
    text-indent: 1em;
    border-bottom: 1px dotted;
    padding-bottom: 15%;
    padding-top: 5%;
  }

  .troTel-con-for-p2, .troTel-con-for-p3 {
    text-align: left;
  }
  .troTel-con-for-p3 {
    padding: 3%;
  }
  .troTel-for{
    padding: 2%;
  }
  /*培训end*/
}

@media screen and (min-width: 600px) {
  .troTel {
    display: none;
  }
}

/*PC*/

/* banner图 */
.pro_ban img {
  width: 100%;
}

/* 面包屑 加标题 */
.cppx {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.mbx {
  width: 1200px;
  margin: 1% auto;
}

.pro-1 {
  margin-top: 1%;
}

.pro-1 > .pro_zh {
  height: 45px;
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}

.pro-1 > .pro_en {
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}

.pro-1 > .pro_xian {
  width: 110px;
  height: 2px;
  background: #437ff6;
  margin: 0 auto;
  margin-top: 10px;
}

.product_chanp_nav {
  margin-top: 2%;
}

/* 产品培训内容 */
.cppx-2 {
  display: flex;
  flex-wrap: wrap;
  margin: 4% 0;
}

.cppx-2-1 {
  width: 25%;
  padding: 1%;
  border: 1px solid;
  margin: 1% 3%;
}

.cppx-2-1 img {
  width: 100%;
}

.cppx_p1 {
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  line-height: 30px;
}

.cppx_2_2 {
  width: 85%;
  margin: 0 auto;
  text-align: left;
}

.cppx_p2 {
  margin-top: 4%;
  padding-bottom: 4%;
  border-bottom: 1px dotted;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cppx_p4 {
  margin-top: 4%;
  font-size: 12px;
}

.cppx_2_2_hover:hover {
  width: 100%;
  background: #437ff6;
  color: #ffffff;
}
</style>
