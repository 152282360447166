export default function loadBMap() {
	return new Promise(function(resolve, reject) {
		window.onBMapCallback = function () {
			resolve()
		}
		let script = document.createElement('script')
		script.type = 'text/javascript'
		script.src = 'https://api.map.baidu.com/api?v=2.0&ak=MCCI9HFLMRiP8SW7DI0CWOuuRyfUSjoH&callback=onBMapCallback'
		script.onerror = reject
		document.head.appendChild(script)
	})
}