<template>
  <div>
    <div class="cooPc">
      <div class="pro_ban">
        <img src="@/assets/swhz300.png"
             alt="商务合作" />
      </div>
      <div class="sw_main">
        <div class="sczs">
          <p>市场招商</p>
        </div>
        <div class="formation">
          <div class="caption">
            <p>公司名称</p>
            <p>上海杲弢商贸有限公司</p>
          </div>
          <div class="content">
            <p>联系人</p>
            <p>13122486072(高先生)</p>
          </div>
          <div class="email">
            <p>邮箱</p>
            <p>di.wu@shanghaikelin.com</p>
          </div>
          <div class="add">
            <p>市场部办公地址</p>
            <p>上海市浦东新区沈杜公路118空间10号楼302</p>
          </div>
        </div>
      </div>
      <div class="mapandxx">
        <div class="map">
          <baidu-map class="map"
                     :center="map.center"
                     :zoom="map.zoom"
                     @ready="handler">
            <!--缩放-->
            <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
            <!--定位-->
            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                            :showAddressBar="true"
                            :autoLocation="true"></bm-geolocation>
            <!--点-->
            <bm-marker :position="map.center"
                       :dragging="map.dragging"
                       animation="BMAP_ANIMATION_DROP">
              <!--提示信息-->
              <bm-info-window :show="map.show">
                <p class="gongsi">上海杲弢商贸有限公司</p>
                <p>电话：13122486072(高先生)</p>
                <p>地址：上海市浦东新区沈杜公路118空间10号楼302</p>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
        <div class="information">
          <p class="in-gs">上海杲弢商贸有限公司</p>
          <el-form :label-position="labelPosition"
                   label-width="80px"
                   :model="formLabelAlign">
            <el-form-item label="姓名">
              <el-input v-model="formLabelAlign.msgName"></el-input>
            </el-form-item>
            <el-form-item label="联系方式">
              <el-input v-model="formLabelAlign.msgPhone"></el-input>
            </el-form-item>
            <el-form-item label="留言内容">
              <el-input v-model="formLabelAlign.msgContext"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary"
                         @click="submitForm()">立即提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="cooTel">
      <div>
        <!--轮播      -->
        <div class="banTel">
          <el-carousel :interval="5000" arrow="never" class="el-carousel-container">
            <el-carousel-item v-for="item in imgListTel"
                              :key="item.id" class="el-car-container">
              <img class="banTel-img" :src="item.idView"
                   alt="杲弢">
            </el-carousel-item>
          </el-carousel>
        </div>
        <!--公告      -->
        <div class="lb_te">
          <img class="sy_gg_img"
               src="@/assets/sy_gg.png">
          <p class="sy_gg">公告</p>
          <p class="sy_gg_sx"></p>
          <el-carousel height="30px"
                       :interval="33333000"
                       direction="vertical"
                       :autoplay="true"
                       indicator-position="none">
            <el-carousel-item v-for="item in items"
                              :key="item">
              <h3 class="medium">{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      <!--  联系我们      -->
        <div>
          <div class="messTel">
            <p class="messTel-p1">公司地址</p>
            <p class="messTel-p2">The company address</p>
          </div>
          <div>
            <baidu-map class="map"
                       :center="map.center"
                       :zoom="map.zoom"
                       @ready="handler">
              <!--缩放-->
              <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
              <!--定位-->
              <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"
                              :showAddressBar="true"
                              :autoLocation="true"></bm-geolocation>
              <!--点-->
              <bm-marker :position="map.center"
                         :dragging="map.dragging"
                         animation="BMAP_ANIMATION_DROP">
                <!--提示信息-->
                <bm-info-window :show="map.show">
                  <p class="gongsi">上海杲弢商贸有限公司</p>
                  <p>电话：13122486072(高先生)</p>
                  <p>地址：上海市浦东新区沈杜公路118空间10号楼302</p>
                </bm-info-window>
              </bm-marker>
            </baidu-map>
          </div>
          <div>
            <p></p>
            <p></p>
          </div>
          <div>
            <div class="messTel2">
              <p class="messTel-p1">在线留言</p>
              <p class="messTel-p2">Online Message</p>
            </div>
            <div class="information">
              <p class="in-gs">上海杲弢商贸有限公司</p>
              <el-form :label-position="labelPosition"
                       label-width="80px"

                       :model="formLabelAlign">
                <el-form-item label="姓名">
                  <el-input v-model="formLabelAlign.msgName"></el-input>
                </el-form-item>
                <el-form-item label="联系方式">
                  <el-input v-model="formLabelAlign.msgPhone"></el-input>
                </el-form-item>
                <el-form-item label="留言内容">
                  <el-input v-model="formLabelAlign.msgContext"></el-input>
                </el-form-item>

                <el-form-item class="lyTel-button">
                  <el-button type="primary"
                             @click="submitForm()">立即提交</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { message } from '@/api/gw.js'
export default {
  data () {
    return {
      map: {
        center: { lng: 121.585275, lat: 31.090627 },
        zoom: 16,
        show: true,
        dragging: true
      },
      labelPosition: 'right',
      formLabelAlign: {},
      /*轮播*/
      imgListTel: [
        {id: 0, idView: require('@/assets/telban.png')},
        {id: 1, idView: require('@/assets/telban.png')},
        {id: 2, idView: require('@/assets/telban.png')}
      ],
      /*下单*/
      items: [
        '**客户下单2桶BBA',
        '**客户下单6桶BBA 4桶GTPC',
        '**客户下单2桶GTPC 6桶PTRA',
        '**客户下单6桶MP1H 1桶PTRA',
        '**客户下单2桶ZSJ 5桶PTRA',
        '**客户下单1桶2HMP 3桶PTRA',
      ],
    }
  },
  methods: {
    Message () {
      message().then(res => {
        res
        if (this.msgName == '' || this.msgPhone == '' || this.msgContext == '') {
          this.$message.error('请填写完整');
        }
      })
    },
    handler ({ BMap, map }) {
      // let me = this;
      console.log(BMap, map)
      // 鼠标缩放
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener('click', function (e) {
        console.log(e.point.lng, e.point.lat)
      })
    },
    submitForm () {

      console.log(this.formLabelAlign)
      message(this.formLabelAlign).then(val => {
        console.log(val)
      })
      // this.$refs[formName].validate((valid) => {
      //   if (valid) {
      //     alert('submit!');
      //   } else {
      //     console.log('error submit!!');
      //     return false;
      //   }
      // });
    },
  },
  mounted () {
    this.Message();
  }
};
</script>

<style scoped>
@media screen and (min-width: 601px){
  .cooTel{
    display: none;
  }
}
/*Tel*/
@media screen and (max-width: 600px){
  .cooPc{
    display: none;
  }
  .cooTel{
    display: block;
  }
  /*轮播*/
  /deep/ .el-carousel__container{
    height: auto;
  }

  .banTel-img {
    width: 100% !important;
  }

  .el-carousel-container {
    height: 190px;
  }

  /deep/ .el-car-container {
    height: auto;
  }

  /*轮播 end*/
  /*公告*/
  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel {
    margin: 0 auto;
    width: inherit;
  }

  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .el-carousel__item {
    width: 100%;
    margin: 0 auto;
  }

  .el-carousel {
    margin: 0 auto;
  }


  .el-carousel--horizontal {
    overflow-x: hidden;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }
  /*公告 end*/

  /*留言*/
  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }

  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel__item {
    height: auto;
  }
  /*留言 end*/

  /*地图*/
  .map{
    width: 100%!important;
  }
  /*地图 end */
  /*留言*/
  .messTel{
    text-align: center;
    margin-bottom: 2%;
  }
  .messTel2{
    margin: 4%;
    text-align: center;
  }
  .messTel-p1{
    font-size: 1.2em;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
    opacity: 1;
    line-height: 1.5;
  }
  .messTel-p2{
    font-size: 1em;
    font-family: Source Han Sans SC;
    font-weight: 300;
    line-height: 13px;
    color: #666666;
    opacity: 1;
    line-height: 1.5;
  }
  .information{
    width: 100% !important;
    background-image: url("../../assets/lybj.png");
    padding-bottom: 4%;
  }
  /deep/ .el-form-item__content{
    margin-left: 0!important;
  }
  .el-form{
    padding: 4%;
    background-color: rgba(0,0,0,.2);
    margin: 0 4%;
  }
  /deep/ .el-form-item__label{
    color: white;
  }
  .lyTel-button{
    text-align: center;
    margin-left: 0!important;
  }
  /*留言 end*/




}


/*PC*/
.pro_ban img {
  width: 100%;
}

.sw_main {
  width: 80%;
  margin: 0 auto;
}
.sczs {
  height: 100px;
  line-height: 100px;
  font-size: 1.5rem;
  border-bottom: #eeeeee solid 1px;
}
.formation {
  margin-top: 20px;
  display: flex;
  border-bottom: #eeeeee solid 1px;
}
.caption {
  width: 25%;
}
.caption > p:nth-child(1) {
  color: #00a1ba;
  font-size: 14px;
  height: 33px;
  line-height: 33px;
}
.caption > p:nth-child(2) {
  font-size: 1rem;
  height: 33px;
  line-height: 33px;
}
.content {
  width: 25%;
}
.content > p:nth-child(1) {
  color: #00a1ba;
  font-size: 14px;
  height: 33px;
  line-height: 33px;
}
.content > p:nth-child(2) {
  font-size: 1rem;
  height: 33px;
  line-height: 33px;
}
.email {
  width: 25%;
}
.email > p:nth-child(1) {
  color: #00a1ba;
  font-size: 14px;
  height: 33px;
  line-height: 33px;
}
.email > p:nth-child(2) {
  font-size: 1rem;
  height: 33px;
  line-height: 33px;
}
.add {
  width: 25%;
  margin-bottom: 20px;
}
.add > p:nth-child(1) {
  color: #00a1ba;
  font-size: 14px;
  height: 33px;
  line-height: 33px;
}
.add > p:nth-child(2) {
  font-size: 1rem;
  height: 33px;
  line-height: 33px;
}
/* 地图 */
.information {
  width: 50%;
}
.map {
  width: 80%;
  height: 400px;
}
.mapandxx {
  width: 80%;
  margin: 3% auto;
  display: flex;
}
.gongsi {
  font-size: 1rem;
  color: red;
}
.in-gs {
  font-size: 1.5rem;
  height: 50px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 20px;
}
.el-form-item__content {
  text-align: center;
}
</style>
