<template>
  <footer>
    <div class="footer">
      <div class="foo">
        <div class="foo_">
          <!-- <p class="foo_p">上海杲弢商贸有限公司</p> -->
          <div>
            <p><img src="@/assets/wechart.png" alt="" /></p>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">产品中心</p>
            <div class="foo_p_d">
              <router-link :to="'Product'">
                <p>地砖修复</p>
              </router-link>
              <router-link :to="'News'">
                <p>玻璃工具</p>
              </router-link>
              <router-link :to="'About'">
                <p>养护石材</p>
              </router-link>
              <router-link :to="'Cooperation'">
                <p>蒸汽机</p>
              </router-link>
              <router-link :to="'Cooperation'">
                <p>磨片</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">合作商家</p>
            <div class="foo_p_d">
              <p>盒马鲜生</p>
              <p>华东理工</p>
              <p>大润发</p>
              <p>欧尚</p>
              <p>苏果</p>
            </div>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">业务系统</p>
            <div class="foo_p_d">
              <p>隐私政策</p>
              <p>用户协议</p>
              <p>版权政策</p>
              <p>免责声明</p>
              <p>意见反馈</p>
            </div>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">关于杲弢</p>
            <div class="foo_p_d">
              <p>接单派单</p>
              <p @click="toLT">杲弢论坛</p>
              <router-link :to="'Express'">
                <p>快递查询</p>
              </router-link>
              <p>ERP系统</p>
              <p @click="toBB">聊天室</p>
            </div>
          </div>
        </div>
        <!-- <div class="foo_">
          <div>
            <p class="foo_p">快递查询</p>
            <div class="foo_p_d">
              <router-link :to="'Express'">
                <p>优速快递</p>
              </router-link>
              <router-link :to="'Express'">
                <p>顺丰快递</p>
              </router-link>
              <router-link :to="'Express'">
                <p>中通快递</p>
              </router-link>
              <router-link :to="'Express'">
                <p>EMS</p>
              </router-link>
              <router-link :to="'Express'">
                <p>京东</p>
              </router-link>
            </div>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">erp</p>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p class="foo_p">接派单</p>
            <div class="foo_p_d">
              <p>接派单接派单接派单接派单接派单</p>
            </div>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p @click="toLT"
               class="foo_p">论坛</p>
          </div>
        </div>
        <div class="foo_">
          <div>
            <p @click="toBB"
               class="foo_p">聊天室</p>
          </div>
        </div> -->
      </div>
    </div>
    <div class="foo2">
      <div class="foo_xx1">
        <p>公司总部地址：上海市 浦东新区 沈杜公路118创业园10号楼302</p>
        <p>总部电话：131 2248 6072 高先生</p>
      </div>
      <div class="foo_xx2">
        <p>© 2015-2020 gaotaoshangmao.com 版权所有</p>
        <p>
          <a
            style="color: #c0c0c0"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >备案号:</a
          ><a
            style="color: #c0c0c0"
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >沪ICP备2020032149号-1</a
          >
        </p>
      </div>
    </div>
    <div class="footerTel">
      <div class="wechartTel">
        <img data-v-40ab164b="" src="/img/wechart.217dee56.png" alt="" />
      </div>
      <p class="contactsTel">总部电话：131 2248 6072 高先生</p>
      <p class="addressTel">
        公司总部地址：上海市 浦东新区 沈杜公路118创业园10号楼302
      </p>
      <p class="baTel">
        Copyright © 2020 可蔺官网 版权所有 沪ICP备2020032149号-1
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    msg: String,
  },
  data() {
    return {
      luntan: 'http://lt.gaotaoshangmao.com/',
      chatBB: 'https://111.229.127.113:10001/webSocket',
    };
  },
  methods: {
    toLT() {
      window.location.href = this.luntan;
    },
    toBB() {
      window.location.href = this.chatBB;
    },
  },
  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.footer {
  background: black;
}
.foo {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 0 auto;
  color: #f5f5f5;
  padding: 1% 0;
}
.foo_ div p img {
  width: 60%;
}
.foo_ div p {
  text-align: center;
}
.foo_p {
  margin-bottom: 10px;
  text-align: center;
}
.foo_p_d p {
  color: #c0c0c0;
  font-size: 12px;
  /* line-height: 23px; */
}
.foo_p_d p:hover {
  color: #00bfff;
}
.foo2 {
  background: #000000;
  padding-bottom: 1%;
}
.foo_xx1 {
  display: flex;
  justify-content: space-between;
  color: #c0c0c0;
  width: 1200px;
  margin: 0 auto;
  border-bottom: 1px solid white;
  padding-bottom: 0.5%;
}
.foo_xx2 {
  display: flex;
  justify-content: space-between;
  color: #c0c0c0;
  width: 1200px;
  margin: 0 auto;
  padding-top: 0.5%;
}
.footerTel,
.footerTel2 {
  display: none;
}

/*Tel*/
@media screen and (max-width: 600px) {
  .footer,
  .foo2 {
    display: none;
  }
  .footerTel {
    display: block;
    background: black;
    color: #eeeeee;
    text-align: center;
  }
  .wechartTel img {
    width: 25%;
    padding: 2% 0;
  }
  .contactsTel {
    padding: 1% 0;
  }
  .addressTel {
    font-size: 12px;
    border-bottom: 1px solid;
    padding-bottom: 1%;
  }
  .baTel {
    font-size: 12px;
    padding-bottom: 1%;
  }
}
</style>
