<template>
  <div class="product">
    <div class="pro_ban">
      <img src="@/assets/ynjd.png"
           alt="产品" />
    </div>
    <div class="proinfo">
      <div class="proinfo_">
        <h2>{{proinfo.productName}}</h2>
        <div class="proinfo_tas">
          <span>时间：{{proinfo.createTime}}</span>
          <span>作者：{{proinfo.applyObject}}</span>
          <span>浏览量：{{proinfo.productCode}}</span>
        </div>
      </div>
    </div>

    <div class="proinfo_productDescription">
      <div>
        {{proinfo.productDescription}}
      </div>
    </div>

  </div>
</template>

<script>
import { listId } from '@/api/gw.js'
export default {
  data () {
    return {
      proinfo: [],

    }
  },
  methods: {
    getId () {
      console.log("子页面id" + this.$route.query.id)
    },
    getListId () {
      listId({ id: this.$route.query.id }).then(res => {
        this.proinfo = res.data.pro
        // console.log("res"+res)
        // id = this.$route.query.id
        // console.log("id"+id)
        // this.proinfo = res.data.data
        // console.log("proinfo"+proinfo)

      })
    },
  },
  mounted () {
    this.getId();
    this.getListId();
  }

}
</script>

<style scoped>
.proinfo_,
.proinfo_productDescription {
  width: 80%;
  margin: 0 auto;
}
.proinfo_ h2 {
  text-align: center;
  margin: 1% 0;
  font-size: 25px;
  font-weight: 600;
}
.proinfo_tas {
  text-align: center;
}
</style>