<template>
  <div class="product">
    <div class="proPc">
      <!-- Banner -->
      <div class="pro_ban">
        <img src="@/assets/cpzx.png"
             alt="产品"/>
      </div>
      <!-- 面包屑 -->
      <div class="breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right"
                       replace>
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <p v-for="(item, index) in nav"
               :key="index">
            <span @click="getNav(++index)"
                  v-if="++index == prdCategoryId">{{ item.name }}</span>
            </p>
          </el-breadcrumb-item>
        </el-breadcrumb>
        <!-- 搜索框 -->
        <div>
          <el-input v-model="search.productName" placeholder="请输入内容"></el-input>
          <el-button type="primary" icon="proSearch" @click="proSearch">搜索</el-button>
        </div>
        <!-- <el-autocomplete popper-class="my-autocomplete"
                         v-model="state"
                         :fetch-suggestions="querySearch"
                         placeholder="请输入内容"
                         @select="handleSelect">
          <i class="el-icon-edit el-input__icon"
             slot="suffix"
             @click="handleIconClick">
          </i>
          <template slot-scope="{ item }">
            <div class="name">{{ item.value }}</div>
            <span class="addr">{{ item.address }}</span>
          </template>
        </el-autocomplete> -->
      </div>
      <!-- 产品 -->
      <div class="product_chanp">
        <div class="pro-1">
          <p class="pro_zh">产品中心</p>
          <p class="pro_en">PRODUCT CENTER</p>
          <p class="pro_xian"></p>
        </div>
        <!-- <div class="product_chanp_nav">
          <el-tabs v-model="activeName">
        <el-tab-pane label="地砖修复">
          <DiZhuan></DiZhuan>
        </el-tab-pane>
        <el-tab-pane label="配置管理">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿"
                     name="fourth">定时任务补偿</el-tab-pane>
        </el-tabs>
      </div> -->
        <div class="pro_pos">
          <!--    导航      -->
          <div class="pro_nav">
            <ul v-for="(navs , index) in nav"
                :key="index">
              <li @click="getNav(++index)">
                <i class="el-icon-collection-tag"></i>{{ navs.name }}
              </li>
            </ul>
          </div>
          <!--    产品      -->
          <div class="pro_dz">
            <div v-for="(pro,index) in pro1"
                 :key="index"
                 class="pro_dz_1">
              <img :src="pro.img"
                   alt="">
              <div class="pro_dz_cp">
                <p class="pro_dz_cp_title">{{ pro.productName }}</p>
                <p>{{ pro.applyMethod }}</p>
                <p>{{ pro.productDescription }}</p>
                <div class="pro_dz_spec">
                  <p>包装规格：{{ pro.productSize }} </p>
                  <p>产品编号：{{ pro.productCode }}</p>
                </div>
              </div>
            </div>
          </div>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="limitePage.page"
              :page-sizes="[12,24]"
              :page-size.sync="limitePage.limit"
              layout="total,prev, pager, next, jumper"
              :total="totalCount"
              background>
          </el-pagination>
          <!-- <el-row>
            <el-col :span="3"
                    v-for="(pro, index) in pro1"
                    :key="index"
                    :offset="index > 0 ? 3 : 0">
              <router-link :to="{path:'/Proinfo',query:{id:pro.productId}}">
                <el-card :body-style="{ padding: '0px' }">
                  <el-image style="width:100%;height:150px"
                            :src="pro.img"></el-image>
                  <div style="padding: 14px;">
                    <span>{{pro.productName}}</span>
                    <div class="bottom clearfix">
                      <time class="time">{{ pro.productDescription }}</time>
                      <el-button type="text"
                                 class="button">详情</el-button>
                    </div>
                  </div>
                </el-card>
              </router-link>
            </el-col>
          </el-row> -->
        </div>
      </div>
      <!-- <div class="gtpro">
        <div class="pro_title">
          <h2>我们的产品</h2>
        </div>
        <div class="pro_pos">
          <div class="pro_nav">
            <ul v-for="(navs , index) in nav"
                :key="index">
              <li @click="getNav(index++)">
                <i class="el-icon-collection-tag"></i>{{navs.name}}
              </li>
            </ul>
          </div>
          <el-row>
            <el-col :span="3"
                    v-for="(pro, index) in pro1"
                    :key="index"
                    :offset="index > 0 ? 3 : 0">
              <router-link :to="{path:'/Proinfo',query:{id:pro.productId}}">
                <el-card :body-style="{ padding: '0px' }">
                  <el-image style="width:100%;height:150px"
                            :src="pro.img"></el-image>
                  <div style="padding: 14px;">
                    <span>{{pro.productName}}</span>
                    <div class="bottom clearfix">
                      <time class="time">{{ pro.productDescription }}</time>
                      <el-button type="text"
                                 class="button">详情</el-button>
                    </div>
                  </div>
                </el-card>
              </router-link>
            </el-col>
          </el-row>
        </div>

        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="limitePage.page"
                       :page-sizes="[12,24]"
                       :page-size="limitePage.limit"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="pro.totalCount"
                       background>
        </el-pagination>

      </div> -->
    </div>
    <div class="proTel">
      <!--轮播     el-carousel__container  -->
      <div class="banTel">
        <el-carousel :interval="5000" arrow="never" class="el-carousel-container">
          <el-carousel-item v-for="telLb in imgListTel"
                            :key="telLb.id" class="el-car-container">
            <img class="banTel-img" :src="telLb.idView"
                 alt="杲弢">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--公告      -->
      <div class="lb_te">
        <img class="sy_gg_img"
             src="@/assets/sy_gg.png">
        <p class="sy_gg">公告</p>
        <p class="sy_gg_sx"></p>
        <el-carousel height="30px"
                     :interval="33333000"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none">
          <el-carousel-item v-for="telGg in items"
                            :key="telGg">
            <h3 class="medium">{{ telGg }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--产品中心      -->
      <div class="">
        <div class="proRel-title">
          <p class="proRel-title-p1">产品中心</p>
          <p class="proRel-title-p2">Product center</p>
        </div>
        <div class="proTel-con">
          <div v-for="(telPro,index) in pro1" class="proTel-con-for"
               :key="index">
            <div class="proTel-con-for-img">
              <img :src="telPro.img" alt="">
            </div>
            <div class="proTel-con-for-2">
              <p>{{ telPro.productName }}</p>
              <p class="proTel-con-for-p2">{{ telPro.productDescription }}</p>
              <div class="proTel-con-for-jj">
                <p>包装规格：{{ telPro.productSize }} </p>
                <p>产品编号：{{ telPro.productCode }}</p>
              </div>
            </div>
          </div>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page="limitePage.page"
                         :page-size="limitePage.limit"
                         layout="prev, pager, next"
                         :total="totalCount"
                         background>
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DiZhuan from './page/ProDiZhuan'
import {listAll} from '@/api/gw.js'
export default {
  data() {
    return {
      prdCategoryId: '0',  // 默认值1
      pro: {},
      pro1: [],

      pageSize:12,//一页展示几个
      currentPage:1,
      totalCount: 0,//总数量
      totalPage:0,

      limitePage: {},
      nav: [
        // { name: "全部" },
        {name: "地砖修复"},
        {name: "清洁剂"},
        {name: "磨片"},
        {name: "玻璃工具"},
        {name: "石材养护"},
        {name: "蒸汽机"}
      ],
      restaurants: [],
      state: '',
      proAll:[],
      search: {
        pageNo: 1,
        pageSize: 12,
        productName: '',
      },
      /*轮播*/
      imgListTel: [
        {id: 0, idView: require('@/assets/telban.png')},
        {id: 1, idView: require('@/assets/telban.png')},
        {id: 2, idView: require('@/assets/telban.png')}
      ],
      /*下单*/
      items: [
        '**客户下单2桶BBA',
        '**客户下单6桶BBA 4桶GTPC',
        '**客户下单2桶GTPC 6桶PTRA',
        '**客户下单6桶MP1H 1桶PTRA',
        '**客户下单2桶ZSJ 5桶PTRA',
        '**客户下单1桶2HMP 3桶PTRA',
      ],
    };
  },
  components: {
    // "DiZhuan": DiZhuan
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getNav(prdCategoryId) {
      // 点击谁 就把哪个值传进来
      this.prdCategoryId = prdCategoryId
      this.getListId(this.prdCategoryId)
    },
    // 通过id查找分类
    getListId(id) {
      listAll({prdCategoryId: id}).then(res => {
        id = this.prdCategoryId
        this.pro = res.data.page
        this.pro1 = res.data.page.list
        this.totalCount = res.data.page.totalCount
      })
    },
    // 查找全部
    getListAll(page) {
      listAll({page: page, limit: 12}).then(res => {
        this.pro = res.data.page
        this.pro1 = res.data.page.list
      })
    },
    // 模糊查询 star
    getListName() {
      listAll().then(res => {
        this.list = res.data.page.list
      })
    },
    // 2.查找全部
 /*   proListAll(){
      listAll(this.search).then(res =>{
        this.proAll=res.data.page.list
        this.totalCount = res.data.page.totalCount
      })
    },
    proSearch(){
      this.proListAll()
    },*/
    Search() {
      var search = this.search;
      if (search) {
        this.pro1 = this.list.filter(function (product) {
          // 每一项数据
          // console.log(product)
          return Object.keys(product).some(function (key) {
            // 每一项数据的参数名
            // console.log(key)
            return (
                String(product[key])
                    // toLowerCase() 方法用于把字符串转换为小写。
                    .toLowerCase()
                    // indexOf() 方法可返回某个指定的字符串值在字符串中首次出现的位置。
                    .indexOf(search) > -1
            );
          });
        });
      }
    },
    /*    querySearch (queryString, cb) {
          var restaurants = this.restaurants;
          var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
          // 调用 callback 返回建议列表的数据
          cb(results);
        },
        createFilter (queryString) {
          return (restaurant) => {
            return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
          };
        },
        handleSelect (item) {
          console.log(item);
        },
        handleIconClick (ev) {
          console.log(ev);
        },
        模糊查询 end
        handleClick (row) {
          console.log(row);
        },*/
    handleSizeChange(val) {
      this.limitePage.limit = val;
    },
    handleCurrentChange(val) {
      this.limitePage.page = val
      this.getListAll(val)
    }
  },
  mounted() {
    this.getListAll(1);
    this.getListName();
    this.getListId();
    this.restaurants = this.getListAll();
  }

};
</script>

<style scoped>
/*Tel*/
@media screen and (max-width: 600px) {
  .proPc {
    display: none;
  }

  .proTel {
    display: block;
  }

  .proRel-title-p1 {
    font-size: 1.2em;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
    opacity: 1;
    line-height: 1.5;
  }

  .proRel-title-p2 {
    font-size: 1em;
    font-family: Source Han Sans SC;
    font-weight: 300;
    line-height: 13px;
    color: #666666;
    opacity: 1;
    line-height: 1.5;
  }

  /*轮播*/
  /deep/ .el-carousel__container{
    height: auto;
  }

  .banTel-img {
    width: 100% !important;
  }

  .el-carousel-container {
    height: 190px;
  }

  /deep/ .el-car-container {
    height: auto;
  }

  /*轮播 end*/
  /*公告*/
  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel {
    margin: 0 auto;
    width: inherit;
  }

  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .el-carousel__item {
    width: 100%;
    margin: 0 auto;
  }

  .el-carousel {
    margin: 0 auto;
  }


  .el-carousel--horizontal {
    overflow-x: hidden;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }
  /*公告 end*/
  /*产品*/
  .proRel-title {
    text-align: center;
  }

  .proTel-con {
    display: flex;
    flex-wrap: wrap;
  }

  .proTel-con-for {
    width: 41.5%;
    border: 1px dotted #0077FF;
    margin: 2%;
    text-align: center;
  }

  .proTel-con-for-2 {
    padding: 2%;
  }

  .proTel-con-for-img {
    border-bottom: 1px dotted black;

  }

  .proTel-con-for-p2 {
    text-indent: 1em;
    text-align: left;
  }

  .proTel-con-for-img img {
    width: 100%;
  }

  .proTel-con-for-jj {
    display: flex;
    font-size: .2em;
    justify-content: space-between;
  }

  /*  分页*/
  .el-pagination, .is-background {
    width: 100% !important;
    margin: 4% auto !important;
  }
}

/*PC*/
.pro_ban img {
  width: 100%;
}

@media screen and (min-width: 600px) {
  .proTel {
    display: none;
  }
}


/* 搜索 */
li {
  line-height: normal;
  padding: 7px;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.addr {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .addr {
  color: #ddd;
}

.product {
}

.pro_title h2 {
  text-align: center;
  margin: 2% 0;
  font-size: 2em;
  font-weight: 600;
}

.pro_ban {
  position: relative;
  z-index: 23;
}

/*留言*/
.lb_te {
  width: 1200px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  border-bottom: 1px #437ff6 dashed;
  padding-bottom: 10px;
}


.sy_gg_img {
  height: 18px;
}

.sy_gg {
  width: 56px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #437ff6;
  margin: 0 10px;
}

.sy_gg_sx {
  width: 2px;
  height: 18px;
  background: #437ff6;
  margin: 0 10px 0 0;
}

.el-carousel__item h3 {
  color: #437ff6;
  font-size: 18px;
  opacity: 0.75;
  line-height: 30px;
  margin: 0;
}

.el-carousel__item {
  height: auto;
}

/* 分页 */
.el-pagination {
  width: 80%;
  margin: 4% auto;
}

/* 走马灯 */
.el-row {
  width: 80%;
  margin: 0;
}

.el-col-offset-0 {
  margin-left: 2%;
}

.el-col-4 {
  margin-bottom: 1%;
  width: 20%;
}

.el-col-3 {
  margin-bottom: 1%;
  width: 23%;
}

.el-col-offset-3 {
  margin-left: 2%;
}

.time {
  font-size: 13px;
  color: #999;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.button {
  padding: 0;
  float: right;
}

.image {
  width: 100%;
  display: block;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}

.time {
  width: 280px;
  height: 38px;
  line-height: 20px;
  overflow: hidden;
}

/* 产品main */
.product_chanp {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}

.pro-1 > .pro_zh {
  height: 45px;
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}

.pro-1 > .pro_en {
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}

.pro-1 > .pro_xian {
  width: 110px;
  height: 2px;
  background: #437ff6;
  margin: 0 auto;
  margin-top: 10px;
}

.product_chanp_nav {
  margin-top: 2%;
}

.pro_nav_1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px dotted;
  border-top: 1px dotted;
  padding: 1% 0;
}

.pro_nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  padding: 1% 0 0 0;
  margin: 4% 0 2% 0;
}

/* 产品中心 */
.pro_dz {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}

.pro_dz_1 {
  width: 40%;
  display: flex;
  border: 1px dotted black;
  text-align: left;
  align-items: center;
  padding: 1% 4%;
  margin-top: 2%;
}

.pro_dz_1 img {
  width: 30%;
}

.el-image__inner {
  width: auto !important;
}

.pro_dz_cp {
  margin: 0 1% 0 12%;
}

.pro_dz_cp_title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.pro_dz_spec {
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
}

</style>
