<template>
  <div>
    <div class="troInfo_header">
      <div class="troInfo">
        <div class="mbx">
          <el-breadcrumb separator-class="el-icon-arrow-right"
                         replace>
            <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{path: '/Troubleshooting'}">
              产品培训
            </el-breadcrumb-item>
            <el-breadcrumb-item class="mbx_page">
              <p>{{ InfoAll.title }}</p>
            </el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="infoSerarch">
          <el-select
              v-model="value"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="请输入关键词"
              :remote-method="remoteMethod"
              :loading="loading"
          >
            <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>

          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </div>
      </div>
    </div>
    <div class="troInfo_body">
      <div class="troInfo_body_r">
        <div>
          <div class="troInfo_body_jc">
            <p class="troInfo_body_shu"></p>
            <p class="troInfo_body_jc_ul">教程列表</p>
          </div>
          <ul>
              <li v-for="(jcList,index) in rList" :key="index">
                <router-link :to="{path:'/TroubleInfo',query:{id:jcList.detailsId}}">
                  <p class="troInfo_body_jc_ul_li_p">{{ jcList.title }}</p>
                </router-link>
              </li>
          </ul>
        </div>
        <div>
          <div class="troInfo_body_jc">
            <p class="troInfo_body_shu"></p>
            <p class="troInfo_body_jc_ul">推荐教程</p>
          </div>
          <ul>
            <li v-for="(jcList,index) in rList" :key="index">{{ jcList.title }}</li>
          </ul>
        </div>
      </div>
      <div class="troInfo_body_l">
        <p class="troInfo_body_l_title">{{ InfoAll.title }}</p>
        <p style="margin: .5% 0;color: #9e9696;text-align: right;">
          <span>{{ InfoAll.createUser }}</span><span style="margin: 0 1%">•</span>
          <span>发表于：{{InfoAll.createTime}} </span><span style="margin: 0 1%">•</span>
          <span>更新于：{{ InfoAll.updateTime }}</span>
        </p>
        <p>
          {{ InfoAll.categoryContent }}
        </p>
        <br>
        <img style="width: 100%" :src="InfoAll.imgContent" :alt="InfoAll.title">
      </div>
    </div>

  </div>
</template>

<script>
import {dynamicList,dynamicListById} from '@/api/gw.js'
export default {
  name: "TroubleInfo",
  data() {
    return {
      //详情
      InfoAll:{},
      //左侧教程
      rList:[],
      //远程搜索
      options: [],
      value: [],
      list: [],
      loading: false,
      states: ["Alabama", "Alaska", "Arizona",
        "Arkansas", "California", "Colorado",
        "Connecticut", "Delaware", "Florida",
        "Georgia", "Hawaii", "Idaho", "Illinois",
        "Indiana", "Iowa", "Kansas", "Kentucky",
        "Louisiana", "Maine", "Maryland",
        "Massachusetts", "Michigan", "Minnesota",
        "Mississippi", "Missouri", "Montana",
        "Nebraska", "Nevada", "New Hampshire",
        "New Jersey", "New Mexico", "New York",
        "North Carolina", "North Dakota", "Ohio",
        "Oklahoma", "Oregon", "Pennsylvania",
        "Rhode Island", "South Carolina",
        "South Dakota", "Tennessee", "Texas",
        "Utah", "Vermont", "Virginia",
        "Washington", "West Virginia", "Wisconsin",
        "Wyoming"]

    }
  },
  watch:{
    $route(to,from){
      console.log(to.path);
      console.log(from);
      this.getInfoAll();
      this.rListAll();
      this.getId();
      //远程搜索
      this.list = this.states.map(item => {
        return { value: `value:${item}`, label: `label:${item}` };
      });
    }
  },
  mounted() {
    this.getInfoAll();
    this.rListAll();
    this.getId();
    //远程搜索
    this.list = this.states.map(item => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
    //数组排序
  },
  methods: {
    getId() {
      console.log("子页面id" + this.$route.query.id)
    },
    //查询详情内容
    getInfoAll(){
      dynamicListById({detailsId: this.$route.query.id }).then(res =>{
        this.InfoAll = res.data.data
      })
    },
    //查询左侧教程列表
    rListAll(){
      dynamicList().then(res =>{
        this.rList = res.data.page.list
      })
    },
    //远程搜索
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    }
  },
}
</script>
<style scoped>
.troInfo_header{
  height: 100px;
  background: #437FF6;
  line-height: 100px;
}
.troInfo{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.mbx{
  width: 30%;
}
.mbx_page /deep/ .el-breadcrumb__inner p{
  color: white;
}
.infoSerarch{
  width: 24%;
}
.troInfo_header /deep/ .el-breadcrumb {
  line-height: inherit;

}
.troInfo_header /deep/ .el-breadcrumb .el-breadcrumb__inner.is-link{
  color: white;
}
.troInfo_header /deep/ .el-input{
  width: auto!important;
}
.troInfo_body{
  width: 1200px;
  margin: 0 auto;
  display: flex;
}
.troInfo_body_shu{
  width: 4px;
  height: 24px;
  background: #437FF6;
}
.troInfo_body_jc{
  display: flex;
  line-height: 24px;
  margin: 10% 0;
}
.troInfo_body_jc_ul{
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding-left: 5%;
}
.troInfo_body ul li{
  background: #EEEEEE;
  border-radius: 4px;
  font-size: 15px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #437FF6;
  line-height: 19px;
  margin: 2% 0;
  padding: 2% 10% 2% 6%;
}
.troInfo_body ul li a{
  display: block;
}
.troInfo_body_r{
  width: 20%;
  padding-right: 5%;
  padding-bottom: 5%;
}
.troInfo_body_l{
  width: 80%;
  margin-top: 1.5%;
}
.troInfo_body_l_title{
  height: 35px;
  font-size: 23px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
}
.troInfo_body_jc_ul_li_p{
  background: #EEEEEE;
  border-radius: 4px;
  font-size: 15px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #437FF6;
  line-height: 19px;
  margin: 2% 0;
}
</style>
