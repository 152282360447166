<template>
  <div class="pro_dz">
    <div v-for="(pro,index) in pro1"
         :key="index"
         class="pro_dz_1">
      <!-- <el-image style="width:200px;height:200px;position: initial;display: inline-block; overflow: initial;"
                :src="pro.img"></el-image> -->
      <img :src="pro.img"
           alt="">
      <div class="pro_dz_cp">
        <p class="pro_dz_cp_title">{{pro.productName}}</p>
        <p>{{pro.applyMethod}}</p>
        <p>{{pro.productDescription}}</p>
        <div class="pro_dz_spec">
          <p>包装规格：{{pro.productSize}} </p>
          <p>产品编号：{{pro.productCode}}</p>
        </div>
      </div>
    </div>
    <!-- <div class="pro_dz_1">
      <img src="@/assets/WUL800018.png"
           alt="">
      <div class="pro_dz_cp">
        <p class="pro_dz_cp_title">碟宝洗碗机碱液</p>
        <p>UItra Power MWW Detergent</p>
        <p>浓缩强碱性洗碗机用清洁剂，独特配方可使本品在硬水环境下使用时亦有优秀的清洁去污以及防止水垢沉淀能力。</p>
        <div class="pro_dz_spec">
          <p>包装规格：4*5L </p>
          <p>产品编号：800018</p>
        </div>
      </div>
    </div>
    <div class="pro_dz_1">
      <img src="@/assets/WUL800018.png"
           alt="">
      <div class="pro_dz_cp">
        <p class="pro_dz_cp_title">碟宝洗碗机碱液</p>
        <p>UItra Power MWW Detergent</p>
        <p>浓缩强碱性洗碗机用清洁剂，独特配方可使本品在硬水环境下使用时亦有优秀的清洁去污以及防止水垢沉淀能力。</p>
        <div class="pro_dz_spec">
          <p>包装规格：4*5L </p>
          <p>产品编号：800018</p>
        </div>
      </div>
    </div>
    <div class="pro_dz_1">
      <img src="@/assets/WUL800018.png"
           alt="">
      <div class="pro_dz_cp">
        <p class="pro_dz_cp_title">碟宝洗碗机碱液</p>
        <p>UItra Power MWW Detergent</p>
        <p>浓缩强碱性洗碗机用清洁剂，独特配方可使本品在硬水环境下使用时亦有优秀的清洁去污以及防止水垢沉淀能力。</p>
        <div class="pro_dz_spec">
          <p>包装规格：4*5L </p>
          <p>产品编号：800018</p>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { listAll } from '@/api/gw.js'
export default {
  data () {
    return {
      prdCategoryId: 1,
      pro: '',
      pro1: [],

    }
  },
  methods: {

    // 通过id查找分类
    getListId (id) {
      listAll({ prdCategoryId: id }).then(res => {
        id = this.prdCategoryId
        this.pro = res.data.page
        this.pro1 = res.data.page.list

      })
    },
  },
  mounted () {
    this.getListId();
  },

}
</script>

<style scoped>
.pro_dz {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.pro_dz_1 {
  width: 40%;
  display: flex;
  border: 1px dotted black;
  text-align: left;
  align-items: center;
  padding: 1% 4%;
  margin-top: 2%;
}
.pro_dz_1 img {
  width: 30%;
}
.el-image__inner {
  width: auto !important;
}
.pro_dz_cp {
  margin: 0 1% 0 12%;
}
.pro_dz_cp_title {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}
.pro_dz_spec {
  display: flex;
  justify-content: space-between;
  margin-top: 6%;
}
</style>