<template>
  <div>
    <div class="mainPC">
      <!-- <el-carousel indicator-position="outside">
        <el-carousel-item v-for="item in imgList"
                          :key="item.id">
          <img :src="item.idView"
               alt="杲弢">
        </el-carousel-item>
      </el-carousel> -->
      <!--   轮播     -->
      <div class="lb_lr">
        <el-carousel :interval="3000"
                     arrow="never"
                     height="580px">
          <el-carousel-item v-for="item in imgList"
                            :key="item.id">
            <img :src="item.idView"
                 alt="杲弢">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--  公告    -->
      <div class="lb_te">
        <img class="sy_gg_img"
             src="@/assets/sy_gg.png">
        <p class="sy_gg">公告</p>
        <p class="sy_gg_sx"></p>
        <el-carousel height="30px"
                     :interval="3000"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none">
          <el-carousel-item v-for="item in items"
                            :key="item">
            <p class="medium">
              {{ item.address }}{{ item.serveContent }}
            </p>
            <p class="medium">
              {{ item.createTime }}
            </p>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 产品     -->
      <div class="product">
        <div class="pro-1">
          <p class="pro_zh">产品中心</p>
          <p class="pro_en">PRODUCT CENTER</p>
          <p class="pro_xian"></p>
        </div>
        <div class="pro-2">

          <div class="pro_2_2"
               v-for="(item , index ) in Pronav"
               :key="index">
            <router-link :to="{path:'/Product'}">
              <img :src="item.img">
              <p class="pro_2_2_p">{{ item.name }}</p>
              <p class="pro_2_2_p1 pro_2_2_none">查看更多</p>
              <div class="sy_pro_hover_none">
                <span class="sy_pro_hover_none_p1">{{ item.name }}</span>
                <span class="sy_pro_hover_none_p2">{{ item.content }}</span>
              </div>
            </router-link>
          </div>
          <!-- <div class="pro_2_2">
            <img src="@/assets/qjj.png">
            <p class="pro_2_2_p">地砖修复</p>
          </div>
          <div class="pro_2_2">
            <img src="@/assets/qjj.png">
            <p class="pro_2_2_p">地砖修复</p>
          </div>
          <div class="pro_2_2">
            <img src="@/assets/qjj.png">
            <p class="pro_2_2_p">地砖修复</p>
          </div>
          <div class="pro_2_2">
            <img src="@/assets/qjj.png">
            <p class="pro_2_2_p">地砖修复</p>
          </div>
          <div class="pro_2_2">
            <img src="@/assets/qjj.png">
            <p class="pro_2_2_p">地砖修复</p>
          </div>-->
        </div>
      </div>
      <!-- 公司简介  -->
      <div class="company">
        <div class="com-1">
          <p class="com_zh">公司简介</p>
          <p class="com_en">COMPANY PROFILE</p>
          <p class="com_xian"></p>
        </div>
        <img src="@/assets/sy_company.png"
             class="com_ban">
        <div class="com_synopsis">
          <p>
            上海杲弢商贸有限公司与上海可蔺清洁用品有限公司同属为姐妹公司与双一流的上海华东理工大学，
            产学研校企联合，创新开发出满足市场需求的清洁产品，我司的核心产品是针对磁砖的修复的系列产品，
            以及石材类养护翻新药剂及磨具磨材料，同时携和记黄埔旗下的上海和黄白猫有限公司的全系清洁剂，
            以及美国金鹰的玻璃工具，共同为我们的客户提供系统的清洁解决方案，我们只做的更好的产品，合作共赢。
            <router-link :to="{path:'/Introduction'}"><span>查看更多>></span></router-link>
          </p>
        </div>
      </div>
      <!-- 员工风采 -->
      <div class="team">
        <div class="com-1">
          <p class="com_zh">核心团队</p>
          <p class="com_en">Staff presence</p>
          <p class="com_xian"></p>
        </div>
        <div class="fc_Carousel">
          <el-carousel :interval="3000"
                       type="card"
                       arrow="never"
                       height="580px"
                       indicator-position="none">
            <el-carousel-item v-for="item in fcList"
                              :key="item">
              <img :src="item.img" :alt="item.employName">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 客户展示 -->
      <!--    <div class="Customer">
        <div class="com-1">
          <p class="com_zh">客户展示</p>
          <p class="com_en">Show customers</p>
          <p class="com_xian"></p>
        </div>
        <div class="fc_Carousel">
          <el-carousel :interval="5000"
                       arrow="always"
                       height="580px">
            <el-carousel-item v-for="item in khList"
                              :key="item">
              <img :src="item.idView">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>-->
      <!-- 新闻热点 -->
      <div class="news">
        <div class="com-1">
          <p class="com_zh">新闻热点</p>
          <p class="com_en">Nwes information</p>
          <p class="com_xian"></p>
        </div>
        <div class="news_2">

            <router-link  :to="{path:'/TroubleInfo',query:{id:news.detailsId}}" v-for="(news,index) in News"
                          :key="index">
              <div class="news_2_n1">
                <img width="50%" :src="news.coverImage" alt="">
                <div style="width: 50%;text-align: left;padding: 3%;">
                  <div class="news_2_title">
                    <p class="news_xian"></p>
                    <span>{{ news.title }}</span>
                  </div>
                  <p class="news_introduce">{{ news.categoryContent }}</p>
                  <p class="news_details">查看详情</p>
                </div>
              </div>
            </router-link>
        </div>
      </div>
      <!-- <div class="index_main">
        <div class="main1">
          <p>留言记录</p>
          <ul>
            <li v-for="(yinan,index) in items"
                :key="index"
                class="main1-news">
              <div>{{yinan}}</div>
            </li>
          </ul>
        </div>
        <div class="main2">
          <img src="@/assets/indexliu2.png"
               alt="">
        </div>
        <div class="main3">
          <p>公司动态</p>
          <ul>
            <li v-for="(News,index) in News"
                :key="index"
                @click=getNews(News.detailsId)
                class="main3-news">
              <div>{{News.title}}</div>
            </li>

          </ul>
        </div>
    </div>-->
    </div>
    <div class="mainTel">
      <!--轮播     el-carousel__container  -->
      <div class="banTel">
        <el-carousel :interval="5000" arrow="never" class="el-carousel-container">
          <el-carousel-item v-for="item in imgListTel"
                            :key="item.id">
            <img class="banTel-img" :src="item.idView"
                 alt="杲弢">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--公告      -->
      <div class="lb_te">
        <img class="sy_gg_img"
             src="@/assets/sy_gg.png">
        <p class="sy_gg">公告</p>
        <p class="sy_gg_sx"></p>
        <el-carousel height="30px"
                     :interval="3000"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none">
          <el-carousel-item v-for="item in items"
                            :key="item">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--产品      -->
      <div class="proTel">
        <div class="proTel-title">
          <p class="proTel-p1">产品中心</p>
          <p class="proTel-p2">Product center</p>
        </div>
        <div class="proTel-cp">
          <div class="proTel-cp-dis"
               v-for="(item , index ) in Pronav"
               :key="index">
            <router-link :to="{path:'/Product'}">
              <img :src="item.img" alt="">
              <p>{{ item.name }}</p>
            </router-link>
          </div>
        </div>
      </div>
      <!--公司      -->
      <div class="comTel">
        <div class="comTel-title">
          <p class="comTel-p1">公司简介</p>
          <p class="comTel-p2">Company profile</p>
        </div>
        <div class="comTel-con">
          <img src="/img/sy_company.789462b8.png" class="">
          <div class="comTel-con-jj">
            <p>
              上海杲弢商贸有限公司办公室地址位于中国大城市，魔都上海，上海市嘉
              定区陈翔路88号1幢2层B区2156室，于2015年02月13日在上海工商局注
              册成立，在公司发展壮大的6年里，我们始终为客户提供好的产品和技术
              支持、健全的售后服务...
            </p><span><router-link :to="{path:'/Introduction'}">查看更多>></router-link></span>
          </div>
        </div>

      </div>
      <!--员工风采      -->
      <div class="staTel">
        <div class="staTel-title">
          <p class="staTel-p1">员工风采</p>
          <p class="staTel-p2">Staff presence</p>
        </div>
        <div class="staTel-con">
          <div v-for="item in fcList"
               :key="item" class="staTel-img">
            <img :src="item.idView">
          </div>
          <div class="staTelMore">
            <p><router-link :to="{path:'/Introduction'}">查看更多</router-link></p>
          </div>
          <!--          <el-carousel :interval="5000" type="card" indicator-position="none" arrow="never" >
                      <el-carousel-item v-for="item in fcList"
                                        :key="item">
                         <img :src="item.idView">
                      </el-carousel-item>
                    </el-carousel>-->
        </div>
      </div>
      <!--新闻      -->
      <div class="newTel">
        <div class="newTel-title">
          <p class="newTel-p1">新闻热点</p>
          <p class="newTel-p2">Nwes information</p>
        </div>
        <div class="newCon">
          <div v-for="(news,index) in News"
               :key="index"
               @click="getNews()" class="newCon-dou">
            <div>
              <img :src="news.imgContent" alt="">
            </div>
            <div>
              <span></span>
              <p>{{ news.title }}</p>
              <p>{{ news.categoryContent }}</p>
            </div>
          </div>
        </div>
        <div class="newTelMore">
          <p><router-link :to="{path:'/Introduction'}">查看更多</router-link></p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// dynamicList
import {listAll, dynamicList,staffStyle,express} from '@/api/gw.js'

export default {
  data() {
    return {
      imgList: [
        {id: 0, idView: require('@/assets/BANNER1.png')},
        {id: 1, idView: require('@/assets/BANNER2.png')},
        {id: 2, idView: require('@/assets/BANNER3.png')},
        {id: 3, idView: require('@/assets/BANNER4.png')}
      ],
      imgListTel: [
        {id: 0, idView: require('@/assets/telban.png')},
        {id: 1, idView: require('@/assets/telban.png')},
        {id: 2, idView: require('@/assets/telban.png')}
      ],
      fcList: [],
      khList: [
        {id: 0, idView: require('@/assets/bm.png')},
        {id: 1, idView: require('@/assets/hm.png')},
        {id: 2, idView: require('@/assets/drf.png')},
        {id: 3, idView: require('@/assets/jlf.png')},
      ],
      items: [],
      News: [],


      Pronav: [
        {
          name: "地砖修复",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/dzxf.png"
        },
        {
          name: "清洁剂",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/qjj.png"
        },
        {
          name: "磨片",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/mp1.png"
        },
        {
          name: "玻璃工具",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/blgj.png"
        },
        {
          name: "石材养护",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/scyh.png"
        },
        {
          name: "蒸汽机",
          content: "PTRA玻化砖修复剂LPTRA特殊玻化砖修复剂GTPC釉面修复剂BBA去污增光剂",
          img: "https://img.gaotaoshangmao.com/img/ch/gtsy/zqj.png"
        }
      ],
    }
  },
  methods: {
    //订单数据
    Express(page,limit){
      express({page:page,limit:limit}).then(res =>{
        this.items = res.data.page.list
      })
    },
    //员工风采
    Team(page,limit){
      staffStyle({page:page,limit:limit}).then(res =>{
        this.fcList =res.data.page.list
        console.log(res)
      })
    },
    // 产品列表
    ProList() {
      listAll().then(res => {
        console.log(res)
      })
    },
    // 新闻列表
    NewsList() {
      dynamicList({categoryId: 2}).then(res => {
        console.log(res)
        this.News = res.data.page.list
        console.log(this.News)
      })
    },
    // 点击跳转详情页
    // getNews(id) {
    //   console.log(id)
    //   this.$router.push({
    //     path: `/Newsinfo/${id}`,
    //   })
    // },
  },
  mounted() {
    this.ProList();
    this.NewsList();
    this.Team(1,20);
    this.Express(1,100);
  },
}
</script>

<style scoped>
/*PC*/
/* 轮播 */
.lb_te .el-carousel__item p {
  color: #437ff6;
  font-size: 18px;
  opacity: 0.75;
  line-height: 30px;
  margin: 0;
}

.el-carousel {
  margin: 0 auto;
  width: inherit;
}

.lb_te {
  width: 1200px;
  margin: 10px auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #437ff6;
  padding-bottom: 10px;
}
.lb_te .el-carousel__item{
  display: flex;
  justify-content: space-between;
}
.lb_lr .el-carousel__item img {
  width: 100%;
  height: 100%;
}

.el-carousel__item {
  width: 100%;
  margin: 0 auto;
}

.el-carousel {
  margin: 0 auto;
}

.el-carousel__item {
  height: 580px;
}

.el-carousel__container {
  position: relative;
  height: 580px;
}

.el-carousel--horizontal {
  overflow-x: hidden;
}

.sy_gg_img {
  height: 18px;
}

.sy_gg {
  width: 56px;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #437ff6;
  margin: 0 10px;
}

.sy_gg_sx {
  width: 2px;
  height: 18px;
  background: #437ff6;
  margin: 0 10px 0 0;
}

/* product */
.product,
.company,
.team,
.Customer,
.news {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  margin-top: 40px;
  /* height: 1100px; */
}

.pro_2_2:hover p {
  background: #437ff6;
  color: white;
}

.sy_pro_hover_none_p1,
.sy_pro_hover_none_p2 {
  display: inherit;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #fefefe;
}

.sy_pro_hover_none_p1 {
  font-size: 18px;
  margin-top: 20%;
}

.sy_pro_hover_none_p2 {
  width: 50%;
  margin: 0 auto;
  margin-top: 10%;
  line-height: 2;
}

.pro_2_2 {
  position: relative;
}

.pro_2_2 .sy_pro_hover_none {
  display: none;
}

.pro_2_2 .pro_2_2_none {
  display: none;
}

.pro_2_2:hover .pro_2_2_none {
  display: block;
}

.pro_2_2:hover .pro_2_2_p {
  display: none;
}

.pro_2_2:hover .sy_pro_hover_none {
  display: block;
  background: #437ff6;
  padding: 15px;
  width: 83.5%;
  height: 78%;
  margin: 0 17px;
}

.sy_pro_hover_none {
  position: absolute;
  top: 0;
}

.pro-1 > .pro_zh {
  height: 45px;
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}

.pro-1 > .pro_en {
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}

.pro-1 > .pro_xian {
  width: 110px;
  height: 2px;
  background: #437ff6;
  margin: 0 auto;
  margin-top: 10px;
}

.pro-2 {
  /* display: flex;
  justify-content: space-between; */
  margin-top: 4%;
  height: 955px;
}

.pro_2_2 {
  width: 33%;
  float: left;
}

.pro_2_2_p {
  width: 83%;
  text-align: center;
  margin: 15px auto;
  height: 15px;
  padding: 15px;
  border: 1px solid;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}

.pro_2_2_p1 {
  width: 83%;
  text-align: center;
  margin: 15px auto;
  height: 15px;
  padding: 15px;
  border: 1px solid;
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
}

.com-1 > .com_zh {
  height: 45px;
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}

.com-1 > .com_en {
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}

.com-1 > .com_xian {
  width: 110px;
  height: 2px;
  background: #437ff6;
  margin: 0 auto;
  margin-top: 10px;
}

.com_ban {
  margin-top: 4%;
}

.com_synopsis {
  margin-top: 1%;
  font-size: 17px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 1.5;
  opacity: 0.6;
}

.com_synopsis p {
  text-align: justify;
}

.com_synopsis p span {
  color: #437ff6;
  font-size: 16px;
}

.Customer {
  margin-top: 0;
}

/* 风采 */
.team .el-carousel__item {
  width: 50% !important;
}

.fc_Carousel {
  margin-top: 4%;
}

.team .el-carousel__item img[data-v-0a614696] {
  /* width: 100%; */
  height: 100%;
}

.staTel-con {
  display: flex;
  flex-wrap: wrap;
}

.staTel-img {
  width: 50%;
}

.staTel-img img {
  width: 100%;
}

/* 客户展示 */

.Customer .el-carousel__item img {
  width: 50%;
}

/* 新闻 */
.news_2 {
  display: flex;
  flex-wrap: wrap;
  margin: 4% 0;
}
.news_2 a{
  width: 47%;
}
.news_2_n1 {
  display: flex;
  border: 1px solid black;
  margin: 1.4%;
}

.news_2_title {
  margin: 20px 0%;
  display: flex;
  /*justify-content: center;*/
  align-items: center;
}

.news .news_2 .news_xian {
  width: 3px;
  height: 16px;
  background: #437ff6;
  display: inline-block;
}

.news_introduce {
  text-align: initial;
  /*margin: 15% 0%;*/
  width: 90%;   /*一定要设置宽度，或者元素内含的百分比*/
  overflow:hidden; /*溢出的部分隐藏*/
  white-space: nowrap; /*文本不换行*/
  text-overflow:ellipsis;/*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}

.news_details {
  color: #437ff6;
  border: 1px dotted black;
  display: inline-block;
  padding: 1% 4%;
  margin: 10px 0;
}

.news_2_title span {
  margin-left: 6%;
  width: 87%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.news_details:hover {
  background-color: #437ff6;
  color: white;
}

.mainTel {
  display: none;
}

/*Tel*/
@media screen and (max-width: 600px) {
  .mainPC {
    display: none;
  }
  .mainTel {
    display: block;
  }
  /*轮播*/
  .el-carousel__container {
    position: relative;
    height: 190px;
  }
  .banTel-img{
    width: 100%!important;
  }
  .el-carousel-container{
    height: 190px;
  }
  /*轮播 end*/
  /*产品*/
  .proTel {
    margin: 0 0 4% 0;
  }

  .proTel-title {
    text-align: center;
    font-size: 15px;
  }

  .proTel-p1, .comTel-p1, .staTel-p1, .newTel-p1 {
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #333333;
    opacity: 1;
    font-size: 1.2rem;
  }

  .proTel-p2, .comTel-p2, .staTel-p2, .newTel-p2 {
    font-family: Source Han Sans SC;
    font-weight: 300;
    color: #666666;
    opacity: 1;
  }

  .proTel-cp-dis {
    width: 50%;
  }

  .proTel-cp {
    display: flex;
    flex-wrap: wrap;
  }

  .proTel-cp-dis {
    width: 44%;
    margin: 3% 3% 0 3%;
    text-align: center;
  }

  .proTel-cp-dis img {
    width: 100%;
  }

  .proTel-cp-dis p {
    margin-top: 4%;
    border: 1px solid;
  }

  /*产品 end*/
  /*公司简介*/
  .comTel {
    margin: 0 0 4% 0;
  }

  .comTel-title {
    text-align: center;
  }

  .comTel-con img {
    width: 100%;
    margin-top: 2%;
  }

  .comTel-con p {
    display: inline;
  }

  .comTel-con-jj {
    padding: 1% 4% 0 4%;
  }

  /*公司简介 end*/
  /*员工风采*/
  .staTel {
    margin: 0 0 4% 0;
    text-align: center;
  }

  .staTel-title {
    text-align: center;
  }

  .el-carousel__item img {
    width: 50%;
    height: auto;
  }

  .el-carousel--horizontal {
    overflow-x: visible;
  }

  .el-carousel--horizontal {
    overflow-x: visible;
  }

  .el-carousel__item {
    height: auto;
  }

  .el-carousel__container {
    height: auto;
  }

  .carousel {
    position: relative;
    height: 220px;
    margin-top: 5%;
  }

  .staTel-img {
    width: 45.3%;
    margin: 2%;
    border: 1px solid #eee;
  }
  .staTelMore,.newTelMore{
    width: 20%;
    margin: 0 auto;
    border: 1px solid #eee;
    background: #437ff6;
    padding: 1%;
  }
  .staTelMore a{
    color: white;
  }
  /*员工风采 end*/
  /*新闻*/
  .newTel {
    margin: 0 0 4% 0;
    text-align: center;
  }

  .newCon {
    display: flex;
    flex-wrap: wrap;
  }

  .newCon-dou {
    width: 45.3%;
    margin: 2%;
    border: 1px solid #eee;
  }

  .newCon-dou img {
    width: 100%;
  }
  .newTelMore a{
    color: white;
  }

}
</style>
