<template>
  <div>
    <!--为echarts准备一个具备大小的容器dom-->
    <div id="main"
         style="width: 100%;height: 800px;">
    </div>

  </div>
</template>

<script>

import echarts from 'echarts';
import '../../../node_modules/echarts/map/js/china.js'
import optionMap from '../../../public/map'
import loadBMap from '../../utils/Bmap.js'

require('echarts/dist/extension/bmap.min.js')


require('echarts/extension/bmap/bmap')
require('echarts-gl/dist/echarts-gl.min.js')
require('echarts-stat/dist/ecStat.min.js')
require('echarts/dist/echarts.min.js')
require('echarts/dist/extension/dataTool.min.js')
require('echarts/map/js/world.js')
require('echarts/map/js/china.js')
export default {
  name: "About",
  components: {},
  data () {
    return {

    }
  },
  methods: {
    echartMap () {
      var mapchart = echarts.init(document.getElementById('main'));
      loadBMap('MCCI9HFLMRiP8SW7DI0CWOuuRyfUSjoH').then(() => {
        mapchart.setOption(optionMap);
      })
    }
  },

  mounted () {
    this.echartMap();
  },
}
</script>

<style scoped>
@media screen and (max-width: 600px){
#main{
  height: 400px!important;
}
}
</style>
