<template>
  <div>
    <div class="intPc">
      <div class="pro_ban">
        <img src="@/assets/gsjj.png"
             alt="公司简介" />
      </div>
      <!-- 面包屑 -->
      <div class="mbx">
        <el-breadcrumb separator-class="el-icon-arrow-right"
                       replace>
          <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            <P>公司简介</P>
            <!-- <p v-for="(item, index) in nav"
               :key="index">
              <span @click="getNav(index++)"
                    v-if="index == prdCategoryId">{{item.name}}</span>
            </p> -->
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="cppx gsjj">
        <div class="pro-1">
          <p class="pro_zh">公司简介</p>
          <p class="pro_en">INTRODUCTION</p>
          <p class="pro_xian"></p>
        </div>
        <div class="gsjj_1"
             style="margin-top:4%;">
          <img src="@/assets/gsjj1.png"
               alt="">
          <p>
            上海杲弢商贸有限公司与上海可蔺清洁用品有限公司同属为姐妹公司与双一流的上海华东理工大学，产学研校企联合，创新开发出满足市场需求的清洁产品，
            我司的核心产品是针对磁砖的修复的系列产品，以及石材类养护翻新药剂及磨具磨材料，同时携和记黄埔旗下的上海和黄白猫有限公司的全系清洁剂，
            以及美国金鹰的玻璃工具，共同为我们的客户提供系统的清洁解决方案，我们只做的更好的产品，合作共赢。
          </p>
          <p>我司为上海市石材行业协会会员单位，上海市重合同守信用企业，我司的化工专业博士1人，经济学硕士中级经济师1人，本科5人。</p>
          <p>为客户提供量身定制的清洁解决方案及配套产品。通过培训普及和提高员工的清洁专业技能，引进先进设备和工艺，人力科学配置，秉承将最好产品展现其最佳的效果理念，最终让客户实现提高清洁标准，节约降费。</p>
          <p>经过多年努力，上海可蔺年销售额已突破千万元。长期合作方主要有大润发、欧尚，盒马、华润苏果、等集团公司。</p>
        </div>
      </div>
      <!--   企业文化  -->
      <div>
        <div class="pro-1 gsjj_qywh">
          <p class="pro_zh">企业文化</p>
          <p class="pro_en">CORPORATE CULTURE</p>
          <p class="pro_xian"></p>
        </div>
        <!--   我们的使命     -->
        <div class="qywh_mission">
          <div class="qywh_mission_gray">
            <p class="qywh_mission_sm"><span class="qywh_mission_sm_sp1">我们的使命</span> / <span class="qywh_mission_sm_sp2">Our Mission</span></p>
            <p class="qywh_mission_smjs">
              为人类创造美好生活、为客户创造价值、为员工创造机会、为股东创造利润、为社会创造财富
            </p>
          </div>
          <div>
            <img src="../../assets/mission1.png" alt="">
          </div>
        </div>
        <!--   我们的愿景     -->
        <div class="qywh_Vision">
          <div>
            <p class="qywh_mission_sm"><span class="qywh_mission_sm_sp1">我们的愿景</span> / <span class="qywh_mission_sm_sp2">Our Vision</span></p>
            <p class="qywh_mission_smjs">
              致力于成为全球领先的清洁类产品供应商
            </p>
          </div>
          <div>
            <img src="../../assets/vision1.png" alt="">
          </div>
        </div>
        <!--   核心价值观    -->
        <div class="qywh_values">
          <div class="qywh_mission_gray">
            <p class="qywh_mission_sm"><span class="qywh_mission_sm_sp1">核心价值观</span> / <span class="qywh_mission_sm_sp2">Core Values</span></p>
          </div>
          <div class="qywh_vision_tab">
            <el-tabs v-model="activeName" @tab-click="handleClick" style="width: 64%;margin: 0 auto;background: white">
              <el-tab-pane label="客户第一" name="first" >
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      关注客户的关注点
                      为客户提供建议和资讯
                      帮助客户成长
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="团队合作" name="second">
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      致力于成为全球领先的清洁类产品供应商
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="拥抱变化" name="third">
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      <span>客户第一：关注客户的关注点，为客户提供建议和资讯，帮助客户成长</span>
                      <span>团队合作：共享共担，以小我完成大我</span>
                      <span>拥抱变化：突破自我，迎接变化</span>
                      <span>诚信：诚实正直，信守承诺</span>
                      <span>激情：永不言弃，乐观向上</span>
                      <span>敬业：以专业的态度和平常的心态做非凡的事情</span>
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="诚信" name="fourth">
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      以服务为基础，以质量为生存，以科技求发展；办实事、求实效，创一流服务品牌，树完美企业形象。
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="激情" name="fifth">
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      <span>开放：博采众长、勇于尝试。</span>
                      <span>和谐：合作协同、共担责任。</span>
                      <span>务实：追求实效、不事张扬。</span>
                      <span>创新：发展科技、创新机制。</span>
                    </p>
                  </div>
                </div>
              </el-tab-pane>
              <el-tab-pane label="敬业" name="sixth">
                <div class="vision_fir">
                  <p><img src="../../assets/first.png" alt=""></p>
                  <div>
                    <p>
                      关注客户的关注点
                      为客户提供建议和资讯
                      帮助客户成长
                    </p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </div>
        <!--   经营理念     -->
        <div class="qywh_Vision">
          <div>
            <p class="qywh_mission_sm"><span class="qywh_mission_sm_sp1">经营理念</span> / <span class="qywh_mission_sm_sp2">Business philosophy</span></p>
            <p class="qywh_mission_smjs">
              以服务为基础，以质量为生存，以科技求发展；办实事、求实效，创一流服务品牌，树完美企业形象
            </p>
          </div>
          <div>
            <img src="../../assets/business1.png" alt="">
          </div>
        </div>
        <!--   企业精神      -->
        <div class="qywh_enterprise">
          <div class="qywh_mission_gray">
            <p class="qywh_mission_sm"><span class="qywh_mission_sm_sp1">企业精神</span> / <span class="qywh_mission_sm_sp2">The spirit of enterprise</span></p>
          </div>
          <div>
            <img src="../../assets/enterprise1.png" alt="企业精神">
            <img src="../../assets/enterprise2.png" alt="企业精神">
            <img src="../../assets/enterprise3.png" alt="企业精神">
            <img src="../../assets/enterprise4.png" alt="企业精神">
          </div>
        </div>
      </div>
      <div class="cppx">
        <div class="pro-1 gsjj_qywh">
          <p class="pro_zh">企业文化</p>
          <p class="pro_en">CORPORATE CULTURE</p>
          <p class="pro_xian"></p>
        </div>
        <div class="gsjj_2">
          <div class="gsjj_2_img1">
            <img src="@/assets/gsjj2.png"
                 alt="">
            <div class="gsjj_2_img1_hover">
              <p class="gsjj_2_img1_hover_p1">经营理念</p>
              <p class="gsjj_2_img1_hover_p2">以服务为基础，以质量为生存，以科技求发展 Take the service as the foundation, take the quality as the survival, strives for the development by the science and technology </p>
              <p class="gsjj_2_img1_hover_p3">办实事、求实效，创一流服务品牌，树完美企业形象 Do practical work, seek practical results, create a first-class service brand, tree perfect corporate image</p>
            </div>
          </div>
          <div>
          <div class="gsjj_2_img1">
            <img src="@/assets/gsjj3.png"
                 alt="">
            <div class="gsjj_2_img1_hover_js">
              <p class="gsjj_2_img1_hover_p1">企业精神</p>
              <p class="gsjj_2_img1_hover_p2">正确的竞争原则、鲜明的社会责任和可靠的价值观念</p>
              <p class="gsjj_2_img1_hover_p3">Correct competition principle, distinct social responsibility and reliable value idea</p>
            </div>
          </div>
          <div class="gsjj_2_img1">
            <img class="gsjj_img_4"
                 src="@/assets/gsjj4.png"
                 alt="">
            <div class="gsjj_2_img1_hover_xx">
              <p class="gsjj_2_img1_hover_p1">企业形象</p>
              <p class="gsjj_2_img1_hover_p2">正确的竞争原则、鲜明的社会责任和可靠的价值观念</p>
              <p class="gsjj_2_img1_hover_p3">Correct competition principle, distinct social responsibility and reliable value idea</p>
            </div>
          </div>

          </div>
        </div>
        <div class="gsjj_3">
          <div class="gsjj_gstj">
            <img src="@/assets/gsjj5.png"
                 alt="">
            <div class="gsjj_gstj_hover">
              <p class="gsjj_gstj_hover_p1">公司团建</p>
              <p class="gsjj_gstj_hover_p2">相互之间的沟通是树立这种信心的基础</p>
              <p class="gsjj_gstj_hover_p3">Mutual communication is the foundation of this confidence</p>
            </div>
          </div>
          <div class="gsjj_gshj">
            <img src="@/assets/gsjj6.png"
                 alt="">
            <div class="gsjj_gshj_hover">
              <p class="gsjj_gstj_hover_p1">公司环境</p>
              <p class="gsjj_gstj_hover_p2">相互之间的沟通是树立这种信心的基础</p>
              <p class="gsjj_gstj_hover_p3">Mutual communication is the foundation of this confidence</p>
            </div>
          </div>
          <div class="gsjj_ygss">
            <img src="@/assets/gsjj7.png"
                 alt="">
            <div class="gsjj_ygss_hover">
              <p class="gsjj_gstj_hover_p1">员工宿舍</p>
              <p class="gsjj_gstj_hover_p2">相互之间的沟通是树立这种信心的基础</p>
              <p class="gsjj_gstj_hover_p3">Mutual communication is the foundation of this confidence</p>
            </div>
          </div>
        </div>
      </div>
      <div class="cppx ygfc">
        <div class="pro-1 gsjj_ygfc">
          <p class="pro_zh">员工风采</p>
          <p class="pro_en">STAFF STYLE</p>
          <p class="pro_xian"></p>
        </div>
        <div class="gsjj_4">
          <el-carousel :interval="5000"
                       type="card"
                       arrow="never"
                       height="600px"
                       indicator-position="none">
            <el-carousel-item v-for="item in fcList"
                              :key="item">
              <img :src="item.img" :alt="item.employName">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="intTel">
      <!--轮播      -->
      <div class="banTel">
        <el-carousel :interval="5000" arrow="never" class="el-carousel-container">
          <el-carousel-item v-for="item in imgListTel"
                            :key="item.id" class="el-car-container">
            <img class="banTel-img" :src="item.idView"
                 alt="杲弢">
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--公告      -->
      <div class="lb_te">
        <img class="sy_gg_img"
             src="@/assets/sy_gg.png">
        <p class="sy_gg">公告</p>
        <p class="sy_gg_sx"></p>
        <el-carousel height="30px"
                     :interval="33333000"
                     direction="vertical"
                     :autoplay="true"
                     indicator-position="none">
          <el-carousel-item v-for="item in items"
                            :key="item">
            <h3 class="medium">{{ item }}</h3>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 公司简介     -->
      <div class="comTel">
        <div class="comTel-title">
          <p class="comTel-title-p1">公司简介</p>
          <p class="comTel-title-p2">Company profile</p>
        </div>
        <div>
          <img src="../../assets/gsjj1.png" alt="">
        </div>
        <p>
          上海杲弢商贸有限公司办公室地址位于中国大城市，魔都上海，上海市嘉定区陈翔路88号1幢2层B区2156室，于2015年02月13日在上海工商局注册成立，在公司发展壮大的6年里，
          我们始终为客户提供好的产品和技术支持、健全的售后服务，我公司主要经营商贸，我们有好的产品和专业的销售和技术团队，我公司属于上海贸易经纪与代理业黄页行业，如果您对我公司的产品服务有兴趣，
          期待您在线留言或者来电咨询...
        </p>
      </div>
      <!-- 企业文化    -->
      <div>
        <div class="comTel-title">
          <p class="comTel-title-p1">企业文化</p>
          <p class="comTel-title-p2">Corporate Culture</p>
        </div>
        <div class="culTel">
          <div class="culTel-img1">
            <img src="../../assets/gsjj2.png" alt="">
            <div class="culTel-po">
              <p class="culTel-po-tit">经营理念</p>
              <div>
                <p>以服务为基础，以质量为生存，以科技求发展</p>
                <p>Take the service as the foundation, take the quality as the survival, strives for the development by the science and technology</p>
              </div>
            </div>
          </div>
          <div class="culTel-img2">
            <img src="../../assets/gsjj3.png" alt="">
            <img class="culTel-img2-2-1" src="../../assets/gsjj4.png" alt="">
          </div>
        </div>
        <div class="culTel-2">
          <div class="culTel-img1-1">
            <img src="../../assets/gsjj5.png" alt="">
          </div>
          <div class="culTel-img2-2">
            <img src="../../assets/gsjj6.png" alt="">
            <img src="../../assets/gsjj7.png" alt="">
          </div>
        </div>
      </div>
      <!-- 员工风采     -->
      <div>
        <div class="comTel-title">
          <p class="comTel-title-p1">员工风采</p>
          <p class="comTel-title-p2">Staff style</p>
        </div>
        <div class="team-for">
          <div v-for="item in fcList.slice(0, 2)"
               :key="item" >
            <img :src="item.idView">
          </div>
        </div>
      </div>
      <div>
        <p class="team-more">查看更多</p>
      </div>
    </div>
  </div>
</template>
<script>

import {staffStyle} from "@/api/gw";

export default {
  name: "Introduction",
  data () {
    return {
      activeName: 'first',
      fcList: [],
      /*轮播*/
      imgListTel: [
        {id: 0, idView: require('@/assets/telban.png')},
        {id: 1, idView: require('@/assets/telban.png')},
        {id: 2, idView: require('@/assets/telban.png')}
      ],
      /*下单*/
      items: [
        '**客户下单2桶BBA',
        '**客户下单6桶BBA 4桶GTPC',
        '**客户下单2桶GTPC 6桶PTRA',
        '**客户下单6桶MP1H 1桶PTRA',
        '**客户下单2桶ZSJ 5桶PTRA',
        '**客户下单1桶2HMP 3桶PTRA',
      ],
    }
  },
  methods: {
    //员工风采
    Team(page,limit){
      staffStyle({page:page,limit:limit}).then(res =>{
        this.fcList =res.data.page.list
        console.log(res)
      })
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
  },

  mounted () {
    this.Team(1,20);
  },
}
</script>

<style scoped>
/*Tel*/
@media screen and (max-width: 600px){
  .intPc{
    display: none;
  }
  /*轮播*/
  /deep/ .el-carousel__container{
    height: auto;
  }

  .banTel-img {
    width: 100% !important;
  }

  .el-carousel-container {
    height: 190px;
  }

  /deep/ .el-car-container {
    height: auto;
  }

  /*轮播 end*/
  /*公告*/
  .el-carousel__item h3 {
    color: #437ff6;
    font-size: 18px;
    opacity: 0.75;
    line-height: 30px;
    margin: 0;
  }

  .el-carousel {
    margin: 0 auto;
    width: inherit;
  }

  .lb_te {
    width: 1200px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    border-bottom: 1px #437ff6 dashed;
    padding-bottom: 10px;
  }

  .el-carousel__item {
    width: 100%;
    margin: 0 auto;
  }

  .el-carousel {
    margin: 0 auto;
  }

  .el-carousel--horizontal {
    overflow-x: hidden;
  }

  .sy_gg_img {
    height: 18px;
  }

  .sy_gg {
    width: 56px;
    font-size: 20px;
    font-family: Source Han Sans SC;
    font-weight: 400;
    color: #437ff6;
    margin: 0 10px;
  }

  .sy_gg_sx {
    width: 2px;
    height: 18px;
    background: #437ff6;
    margin: 0 10px 0 0;
  }
  /*公告 end*/
  /*Tel公司*/
  .comTel img{
    width: 100%;
  }
  .comTel-title{
    text-align: center;
    margin: 4%;
  }
  .comTel-title-p1{
    font-size: 1.2em;
    font-family: Source Han Sans SC;
    font-weight: 400;
    line-height: 17px;
    color: #333333;
    opacity: 1;
    line-height: 1.5;
  }
  .comTel-title-p2{
    font-size: 1em;
    font-family: Source Han Sans SC;
    font-weight: 300;
    line-height: 13px;
    color: #666666;
    opacity: 1;
    line-height: 1.5;
  }
  /*Tel 企业文化*/
  .culTel{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  .culTel-img1{
    width: 45%;
    position: relative;
  }
  .culTel-img2{
    width: 45%;
  }
  .culTel img{
    width: 100%;
  }
  .culTel-po{
    position: absolute;
    top: 0;
    left: 0;
    font-size: .2em;
    text-align: center;
    background: rgba(67,127,246,0.8);
    height: 100%;
    color: white;
  }
  .culTel-po-tit{
    font-weight: 600;
    padding-top: 4%;
    font-family: Source Han Sans SC;
  }
  .culTel-img2-2-1{
    margin-top: 2%;
  }
  .culTel-2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 2%;
  }
  .culTel-2 img{
    width: 100%;
  }
  .culTel-img1-1{
    width: 45%;
  }
  .culTel-img2-2{
    width: 45%;
    display: flex;
    justify-content: space-between;
  }
  .culTel-img2-2 img{
    width: 45%;
  }
  /*Tel 员工风采*/
  .team-for{
    display: flex;
    padding: 4%;
  }
  .team-for img{
    width: 98%;
  }
  .team-more{
    width: 20%;
    text-align: center;
    margin: 2% auto;
    border: 1px solid #eee;
    background: #437ff6;
    color: white;
  }
}

/*PC*/
@media screen and (min-width: 601px){
  .intPc{
    display: block;
  }
  .intTel{
    display: none;
  }
}
/*PC*/
/* banner图 */
.pro_ban img {
  width: 100%;
}
/* 面包屑 加标题 */
.cppx {
  width: 1200px;
  text-align: center;
  margin: 0 auto;
}
.ygfc{
  margin: 2% auto;
}
.mbx {
  width: 1200px;
  margin: 1% auto;
}
.pro-1 {
  margin-top: 1%;
}
.pro-1 > .pro_zh {
  height: 45px;
  font-size: 30px;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}
.pro-1 > .pro_en {
  height: 24px;
  font-size: 18px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  margin-top: 10px;
}
.pro-1 > .pro_xian {
  width: 110px;
  height: 2px;
  background: #437ff6;
  margin: 0 auto;
  margin-top: 10px;
}
.product_chanp_nav {
  margin-top: 2%;
}
/* 详情 */
@keyframes fade-in {
  0% {opacity: 0;}/*初始状态 透明度为0*/
  40% {opacity: 0.5;}/*过渡状态 透明度为0*/
  100% {opacity: 1;}/*结束状态 透明度为1*/
}
@-webkit-keyframes fade-in {/*针对webkit内核*/
  0% {opacity: 0;}
  40% {opacity: 0.5;}
  100% {opacity: 1;}
}
.gsjj_1 {
  -webkit-animation:fade-in 1s;/*针对webkit内核*/
  -webkit-transform: translatey(40px);
  -moz-transform: translatey(40px);
  -o-transform: translatey(40px);
  transform: translatey(40px);

}
.gsjj_1 {
  margin-top: 4%;
  animation: fade-in;/*动画名称*/
  animation-duration: 2.5s;/*动画持续时间*/
  -webkit-transform: translatey(0px);
  -moz-transform: translatey(0px);
  -o-transform: translatey(0px);
  transform: translatey(0px);
}

.gsjj_1 p {
  text-indent: 1em;
  font-size: 17.5px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  text-align: left;
  margin-top: 1%;
}
.gsjj_qywh,
.gsjj_ygfc {
  margin-top: 2%;
  text-align: center;
}
.qywh_mission,.qywh_values{
  background-color: #EEEEEE;
  text-align: center;
}
.qywh_mission_gray{
  margin: 2% 0 0 0;
}
.qywh_Vision{
  text-align: center;
}
.qywh_mission img{
  padding: 0 0 2% 0;
}
.qywh_mission_smjs{
  padding: 0 0 1% 0;
  width: 20%;
  margin: 0 auto;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #333333;
}
.qywh_mission_sm{
  padding: 2% 0 10px 0;
}
.qywh_mission_sm_sp1{
  font-size: 1.3rem;
  font-family: Source Han Sans SC;
  font-weight: 500;
  color: #437FF6;
}
/deep/ .el-tabs__item {
  border-left: 1px solid #eee;
}
.gsjj_2 {
  display: flex;
  margin-top: 4%;
  justify-content: space-between;
  -webkit-transform: translatey(40px);
  -moz-transform: translatey(40px);
  -o-transform: translatey(40px);
  transform: translatey(40px);

}
.gsjj_2{
  -webkit-transform: translatey(0px);
  -moz-transform: translatey(0px);
  -o-transform: translatey(0px);
  transform: translatey(0px);
}
.gsjj_2 .gsjj_img_4 {
  margin-top: 2%;
}
.gsjj_3 {
  display: flex;
  margin-top: 1%;
  justify-content: space-between;
}
.gsjj_4 {
  margin-top: 4%;
}
/* :hover */
.gsjj_2_img1,.gsjj_gstj,.gsjj_gshj,.gsjj_ygss {
  position: relative;
}
.gsjj_2_img1_hover {
  position: absolute;
  top: 0;
  background: black;
  opacity: 0.2;
  color: white;
  height: 51.5%;
  padding: 20%;
}
.gsjj_2_img1_hover:hover {
  position: absolute;
  top: 0;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 51.7%;
  padding: 20%;
}
.gsjj_2_img1_hover_js {
  position: absolute;
  top: 0;
  background: black;
  opacity: 0.2;
  color: white;
  height: 52.7%;
  padding: 9.5%;
}
.gsjj_2_img1_hover_js:hover {
  position: absolute;
  top: 0;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 52.7%;
  padding: 9.5%;
}
.gsjj_2_img1_hover_xx {
  position: absolute;
  top: 4.8%;
  background: black;
  opacity: 0.2;
  color: white;
  height: 50.7%;
  padding: 9.5%;
}
.gsjj_2_img1_hover_xx:hover {
  position: absolute;
  top: 4.8%;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 50.7%;
  padding: 9.5%;
}
.gsjj_2_img1_hover_xx {
  position: absolute;
  top: 4.8%;
  background: black;
  opacity: 0.2;
  color: white;
  height: 50.7%;
  padding: 9.5%;
}
.gsjj_2_img1_hover_xx:hover {
  position: absolute;
  top: 4.8%;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 50.7%;
  padding: 9.5%;
}

.gsjj_gstj_hover {
  position: absolute;
  top: 0%;
  background: black;
  opacity: 0.5;
  color: white;
  height: 37.8%;
  padding: 17.8%;
}
.gsjj_gstj_hover:hover {
  position: absolute;
  top: 0%;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 37.8%;
  padding: 17.8%;
}
.gsjj_gshj_hover {
  position: absolute;
  top: 0%;
  background: black;
  opacity: 0.5;
  color: white;
  height: 37.8%;
  padding: 36.3% 0.8%;
}
.gsjj_gshj_hover:hover {
  position: absolute;
  top: 0%;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 37.8%;
  padding: 36.3% 0.8%;
}
.gsjj_ygss_hover {
  position: absolute;
  top: 0%;
  background: black;
  opacity: 0.5;
  color: white;
  height: 37.8%;
  padding: 36.3% 0.8%;
}
.gsjj_ygss_hover:hover {
  position: absolute;
  top: 0%;
  background: #437ff6;
  opacity: 0.8;
  color: white;
  height: 37.8%;
  padding: 36.3% 0.8%;
}
.gsjj_2_img1_hover_p1,.gsjj_gstj_hover_p1 {
  font-size: 22px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 5%;
}

.gsjj_2_img1_hover_p2,
.gsjj_2_img1_hover_p3 ,.gsjj_gstj_hover_p2,.gsjj_gstj_hover_p3{
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #ffffff;
}
/deep/ .qywh_vision_tab{

}
/deep/ .el-tabs__nav{
  width: 100%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(2){
  width: 17%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(3){
  width: 16.7%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(4){
  width: 16.7%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(5){
  width: 16.5%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(6){
  width: 16.5%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:nth-child(7){
  width: 16.5%;
}
/deep/ .qywh_vision_tab .el-tabs--top .el-tabs__item.is-top:last-child {
  padding-right: 20px;
}
/deep/ .qywh_vision_tab .el-tabs__item.is-active {
  background: #409EFF;
  color: white;
  font-weight: bold;
}
/deep/ .qywh_vision_tab .el-tabs__header {
  margin: 0;
}
.vision_fir{
  display: flex;
  align-items: center;
}
.vision_fir div{
  width: 39%;
  margin: 0 auto;
  text-align: left;
}
.vision_fir div p span{
  display: inline-block;
  margin: 5px 0;
}
.qywh_enterprise{
  background-color: #EEEEEE;
  text-align: center;
}
.qywh_enterprise img{
  padding: 0 8px 2% 0;
}
</style>
