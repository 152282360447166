import {createAPI} from '@/utils/request'

// •	产品分类查询   •	http://111.229.127.113:10001/prd/prdList         get
export const listAll = data => createAPI('api/prd/prdList','get',data)
// •	产品详情查询   •	http://111.229.127.113:10001/prd/prdDetails/{prdId}  get
export const listId = data => createAPI(`api/prd/prdDetails/${data.id}`,'get',data)

// •	动态查询   •	http://111.229.127.113:10001/dynamic/dynamicList     get
export const dynamicList = data => createAPI('api/dynamic/dynamicList','get',data)
// •	动态分类查询   •	https://111.229.127.113:10001/dynamic/dynamicList?categoryId=1
export const dynamicListId = data => createAPI(`api/dynamic/dynamicList?categoryId=${data.categoryId}`,'get')  //${data.CategoryId} 把这个改成CategoryId 你传值的哪个地方的 属性名
// •	动态分类信息查询   •	https://111.229.127.113:10001/dynamic/details/1
export const dynamicListById = data => createAPI(`api/dynamic/details/${data.detailsId}`,'get')  //${data.CategoryId} 把这个改成CategoryId 你传值的哪个地方的 属性名

// •	添加留言查询   • 	http://111.229.127.113:10001/msg/addMsg          post
export const message = data => createAPI('api/msg/addMsg','post',data)

// •	员工风采查询   • 	https://111.229.127.113:10001/staff/limit?page=1&limit=2          get
export const staffStyle = data => createAPI('api/staff/limit','get',data)

// •	订单数据展示   • 	https://111.229.127.113:10001/order/orderList?page=1&limit=2           get
export const express = data => createAPI('api/order/orderList','get',data)
