<template>
  <div>
    <div class="searchBox">
      <div style="margin-top: 15px;">
        <el-input placeholder="请输入内容"
                  v-model="input3"
                  class="input-with-select">
          <el-select v-model="select"
                     slot="prepend"
                     placeholder="请选择">
            <el-option label="优速快递"
                       value="1"></el-option>
            <el-option label="订单号"
                       value="2"></el-option>
            <el-option label="用户电话"
                       value="3"></el-option>
          </el-select>
          <el-button slot="append"
                     icon="el-icon-search"></el-button>
        </el-input>
      </div>

    </div>
    <div class="expre">
      <div class="expre-bg">
        <div class="express1"></div>
        <div class="express2"></div>
        <div class="express3"></div>
      </div>
    </div>
    <div class="hzf">
      <p>典型用户</p>
      <img src="@/assets/exphz1.png"
           alt="">
      <p>典型伙伴</p>
      <img src="@/assets/exphz2.png"
           alt="">
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      input3: '',
      select: '',
    }
  },
  methods: {
    
  },
  mounted () {

  },

};
</script>

<style>
/* 搜索框 */
.searchBox {
  z-index: 96;
  height: 0;
  width: 920px;
  margin: auto;
  position: relative;
  top: 250px;
}

/* 背景 */
.expre {
  min-width: 990px;
  min-height: 280px;
  z-index: 95;
  height: 504px;
  position: relative;
}
.expre-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
}
.express1 {
  background-image: url('../../assets/expressbg2.jpg');
  height: 168px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.express2 {
  background-image: url('../../assets/expressbg.jpg');
  height: 168px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
.express3 {
  background-image: url('../../assets/expressbg3.jpg');
  height: 168px;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: auto 100%;
}
/* 合作方 */
.hzf {
  width: 960px;
  margin: 10px auto;
}
</style>