<template>
  <header>
    <div class="header_nav">
      <div class="logo">
<!--        <img src="@/assets/gtlogo.png" alt/>-->
        <p class="logo_ch" style="margin-top: 10%">上海杲弢商贸有限公司</p>
        <p class="logo_en">Shanghai Gaotao Trading Co.,LTD</p>
        <p class="logo_ch" style="margin-top: 4%">上海可蔺清洁用品有限公司</p>
        <p class="logo_en">Shanghai Kelin Cleaning Products Co., Ltd</p>
      </div>
      <el-menu :default-active="activeIndex"
               class="el-menu-demo"
               mode="horizontal"
               @select="handleSelect">
        <el-menu-item index="1">
          <router-link to="/">
            <span class="nav_ch">首页</span>
            <span class="nav_en">Home</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="2">
          <router-link to="/Introduction">
            <span class="nav_ch">公司简介</span>
            <span class="nav_en">Introduction</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="3">
          <router-link to="/Product">
            <span class="nav_ch">产品中心</span>
            <span class="nav_en">Product</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="4">
          <router-link to="/Troubleshooting">
            <span class="nav_ch">产品培训</span>
            <span class="nav_en">Honor</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="5">
          <router-link to="/Cooperation">
            <span class="nav_ch">联系我们</span>
            <span class="nav_en">Contact</span>
          </router-link>
        </el-menu-item>
        <el-menu-item index="6">
          <router-link to="/About">
            <span class="nav_ch">合作方</span>
            <span class="nav_en">Partner</span>
          </router-link>
        </el-menu-item>
        <!-- <el-menu-item index="5">
          <router-link to="/Product">产品中心</router-link>
        </el-menu-item>
        <el-menu-item index="3">
          <router-link to="/Troubleshooting">疑难解答</router-link>
        </el-menu-item>
        <el-menu-item index="4">
          <router-link to="/News">公司动态</router-link>
        </el-menu-item>
        <el-menu-item index="2">
          <router-link to="/Cooperation">商务合作</router-link>
        </el-menu-item>
        <el-menu-item index="6">
          <router-link to="/About">关于我们</router-link>
        </el-menu-item> -->
      </el-menu>
    </div>
    <div class="telHead">
      <div class="headTel">
        <div class="headTel-i" @click="headTelshow" ><i class="el-icon-s-operation"></i></div>

        <div class="headTel-img"><img  src="/img/gtlogo.0ddc510e.png" alt=""></div>
      </div>
      <div v-if="show" class="headTel-nav">
        <ul>
          <li><router-link to="/">- 首页</router-link> </li>
          <li><router-link to="/Introduction"> - 公司简介</router-link></li>
          <li><router-link to="/Product">      - 产品中心</router-link></li>
          <li><router-link to="/Troubleshooting"> - 产品培训</router-link></li>
          <li><router-link to="/Cooperation">  - 联系我们</router-link></li>
          <li><router-link to="/About">        - 合作方</router-link></li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      show:false,
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    headTelshow:function (){
      this.show=!this.show
    },

  },
  watch:{
    $route: {
      handler:function (){
        this.show=false
      },
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*PC star*/
/* nav */
.logo {
  height: 87px;
  text-align: center;
}

.logo img {
  height: 70px;
}

.logo .logo_ch {
  height: 19px;
  font-size: 18px;
  font-weight: 400;
  color: #437ff6;
  line-height: 20px;
}

.logo .logo_en {
  height: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #437ff6;
}

.el-menu {
  height: 23px;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  text-align: center;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #437ff6;
}

.header_nav {
  display: flex;
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
}

.el-menu.el-menu--horizontal {
  border-bottom: none;
}

.el-menu--horizontal > .el-menu-item {
  height: 43px;
  line-height: 25px;
}

.el-menu-item {
  padding: 0;
  margin: 0 20px !important;
}

.el-menu-item a {
  display: block;
}

.nav_ch {
  font-size: 16px;
  display: block;
  line-height: 1;
}

.nav_en {
  font-size: 14px;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  outline: 0;
  color: #437ff6;
}

.telHead {
  display: none;
}

/* Tel end */
/*@media only screen and (max-width: 400px){*/
@media screen and (max-width: 600px) {
  .header_nav {
    display: none;
    width: 100%;
  }

  .telHead {
    display: block;
  }
  .headTel{
    display: flex;
    align-items: center;
  }
  .headTel-i{
    font-size: 2em;
    color: #437ff6;
  }
  .headTel-img{
    width: 100%;
    text-align: center;
  }
  .headTel-img img{
    width: 10%;
  }
  /*Tel导航*/
  .headTel-nav{
    position: relative;
  }
  .headTel-nav ul{
    width: 50%;
    text-align: center;
    line-height: 2em;
    position: absolute;
    z-index: 100;
    left: 0;
    top: 0;
    background: #0077FF;
    padding-bottom: 20%;
    color: white;
    font-size: 1.2em;
  }
  .headTel-nav li{
    margin: 2% 0;
    border-bottom: 1px solid #eee;
  }
  .headTel-nav li a{
    color: white;
  }
}
</style>
