<template>
  <div>
    <div class="pro_ban">
      <img src="@/assets/news.png"
           alt="杲弢新闻" />
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right"
                     replace>
        <el-breadcrumb-item :to="{path: '/'}">首页</el-breadcrumb-item>
        <el-breadcrumb-item>
          <p v-for="(item, index) in news"
             :key="index"
             :data-index="index">
            <span @click="getNav(index+1)"
                  v-if="index== categoryId">{{item.categoryName}}</span>
          </p>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <!-- 搜索框 star-->
      <div>
        <el-input v-model="search"
                  placeholder="请输入内容"></el-input>
        <el-button type="primary"
                   @click="Search">搜索</el-button>
      </div>
      <!-- 搜索框 end-->
      <!-- <el-autocomplete popper-class="my-autocomplete"
                       v-model="state"
                       :fetch-suggestions="querySearch"
                       placeholder="请输入内容"
                       @select="handleSelect">
        <i class="el-icon-edit el-input__icon"
           slot="suffix"
           @click="handleIconClick">
        </i>
        <template slot-scope="{ item }">
          <div class="name">{{ item.value }}</div>
          <span class="addr">{{ item.address }}</span>
        </template>
      </el-autocomplete> -->
    </div>

    <div class="xw">
      <p>公司动态</p>
    </div>
    <div class="new_main">
      <div class="daohang">
        <ul v-for="(navs , index ) in nav"
            :key="index">
          <li @click="getNav(index)">
            {{navs.categoryName}}
          </li>

        </ul>
      </div>
      <div class="main">
        <ul>
          <li v-for="(news1,index) in newsid"
              :key="index"
              class="main-n"
              @click=getNews(news1.detailsId)>
            <span>·</span>{{news1.title}}
          </li>
        </ul>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="limitePage.page"
                       :page-sizes="[1,2]"
                       :page-size="limitePage.limit"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="news.totalCount"
                       background>
        </el-pagination>
      </div>

      <div class="pro">
        <p class="pro_hot">热门产品</p>
        <el-row>
          <el-col :span="16"
                  v-for="(pros, index) in pro.slice(0,3)"
                  :key="index"
                  :offset="index > 0 ? 2 : 0">
            <router-link :to="{path:'/Proinfo',query:{id:pros.productId}}">
              <el-card :body-style="{ padding: '0px',display: 'flex' }">
                <el-image style="width:100%;height:150px"
                          :src="pros.img"></el-image>
                <div style="padding: 14px;">
                  <span>{{pros.productName}}</span>
                  <div class="bottom clearfix">
                    <time class="time">{{ pros.createTime }}</time>
                  </div>
                </div>
              </el-card>
            </router-link>
          </el-col>
        </el-row>
      </div>

    </div>
  </div>
</template>

<script>
import { dynamicList, dynamicListId, categoryList, listAll } from '@/api/gw.js'
export default {
  data () {
    return {
      pro: [],
      search: [],
      categoryId: "0",
      news: [],
      newsid: {},
      nav: [],
      state: '',
      newstitle: '',
      //分页的data  star
      limitePage: {
        limit: 2,
        page: 1
      },
      //分页的data  end
    }
  },
  methods: {
    // 产品接口  star
    ListAll () {
      listAll().then(res => {
        this.pro = res.data.page.list
        console.log(this.pro)
      })
    },
    // 产品接口  end
    DynamicList (id) {
      dynamicList({ categoryId: id }).then(res => {
        this.newsid = res.data.page.list
      })
    },
    // 动态调用
    DynamicListAll () {
      dynamicList().then(res => {
        console.log(res)
      })
    },
    CategoryList () {
      categoryList().then(res => {
        console.log(res)
        this.nav = res.data.data
        this.newsid = res.data.data
        this.news = res.data.data
      })
    },
    getListId (id) {
      dynamicListId({ categoryId: id }).then(res => {
        id = this.categoryId
        // this.news = res.data.data
        this.newsid = res.data.data
      })
    },
    getNav (categoryId) {
      console.log(categoryId)
      this.categoryId = categoryId
      this.DynamicList(categoryId + 1)
      // this.getListId(this.categoryId)
    },
    // 点击跳转详情页
    getNews (id) {
      console.log(id)
      this.$router.push({
        path: `/Newsinfo/${id}`,
      })
    },
    // handleOpen (key, keyPath) {
    //   console.log(key, keyPath);
    // },
    // handleClose (key, keyPath) {
    //   console.log(key, keyPath);
    // },

    // 搜索框的js   star
    getListName () {
      dynamicList().then(res => {
        this.list = res.data.page.list
      })
    },
    Search () {
      var search = this.search;
      if (search) {
        this.newsid = this.list.filter(function (product) {
          // 每一项数据
          // console.log(product)
          return Object.keys(product).some(function (key) {
            // 每一项数据的参数名
            // console.log(key)
            return (
              String(product[key])
                // toLowerCase() 方法用于把字符串转换为小写。
                .toLowerCase()
                // indexOf() 方法可返回某个指定的字符串值在字符串中首次出现的位置。
                .indexOf(search) > -1
            );
          });
        });
      }
    },
    // querySearch (queryString, cb) {
    //   var restaurants = this.restaurants;
    //   var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
    //   cb(results);
    // },
    // createFilter (queryString) {
    //   return (restaurant) => {
    //     return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
    //   };
    // },
    // handleSelect (item) {
    //   console.log(item);
    // },
    // handleIconClick (ev) {
    //   console.log(ev);
    // },
    // 搜索框的js   end
    // 分页的js   star
    handleSizeChange (val) {
      this.limitePage.limit = val;
    },
    handleCurrentChange (val) {
      // 这里获取当前第几页  然后传给后端
      this.limitePage.page = val
      this.DynamicListAll(val)
    },
    // 分页的js   end
  },
  mounted () {
    this.ListAll();
    this.DynamicList(1);
    this.DynamicListAll();
    this.CategoryList();
    this.getListName();
  }
}
</script>

<style scoped>
/* 标题 */
.xw {
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-size: 2rem;
  margin: 2% 0;
}
.el-menu {
  border-right: none;
}
.new_main {
  display: flex;
  width: 80%;
  margin: 0 auto;
}
.daohang {
  width: 20%;
}
.daohang ul {
  text-align: center;
}
.daohang ul li {
  height: 40px;
  font-size: 1.5em;
}
.main {
  width: 60%;
}
.pro {
  width: 20%;
}
.main-n {
  height: 33px;
  line-height: 33px;
  letter-spacing: 4px;
  padding-left: 5%;
}
/* 产品 */
.image {
  width: 50% !important;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  margin-top: 13px;
  line-height: 12px;
}
.button {
  padding: 0;
  float: right;
}
.image {
  width: 100%;
  display: block;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.el-col-16 {
  width: 100%;
}
.el-col-offset-2 {
  margin-left: auto;
}
.pro_hot {
  height: 33px;
  line-height: 33px;
  font-size: 1rem;
  font-weight: 600;
}
</style>